import { ITransaction } from "../../types/types";

interface Props {
    transaction: ITransaction;
}

export default function TopupFormSummary({ transaction }: Props) {
    return (
        
        <div className="row  p-5  border-bottom">
        <div className="col-xl-7 col-md-12 p-2 border border-right-1">
          <div className="row pb-3 border-bottom">
            <div className="col-12 mt-2 ">
              <h5 className="mb-5">Details</h5>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <h6 className="form-label">
                  Sender Name{" "}
                  <span className="text-red">*</span>
                </h6>
                <p>
                  {transaction?.senderName || (
                    <small>
                      <i>Not provided</i>
                    </small>
                  )}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <h6 className="form-label">Sender Phone</h6>
                <p>
                  {transaction?.senderPhone || (
                    <small>
                      <i>Not provided</i>
                    </small>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <h6 className="form-label">
                  Recipient Name{" "}
                  <span className="text-red">*</span>
                </h6>
                <p>
                  {transaction?.recipientName || (
                    <small>
                      <i>Not provided</i>
                    </small>
                  )}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="form-group">
                <h6 className="form-label">Recipient Phone</h6>
                <p>
                  {transaction?.recipientPhone || (
                    <small>
                      <i>Not provided</i>
                    </small>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-md-12">
          <h5>Topup Information</h5>
          <table className="table mt-5">
            <tr>
              <td className="border-top-0">Country</td>
              <td className="text-end border-top-0">
                {transaction?.country}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Operator</td>
              <td className="text-end border-top-0">
                {transaction?.operatorName}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Product Category</td>
              <td className="text-end border-top-0">
                {transaction?.productCategory}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Product</td>
              <td className="text-end border-top-0">
                {transaction?.productName}
              </td>
            </tr> 
            <tr>
              <td className="border-top-0">Mobile Number</td>
              <td className="text-end border-top-0">
                {transaction?.recipientPhone}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Payment Method</td>
              <td className="text-end border-top-0">
                {transaction?.paymentMethod}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Amount</td>
              <td className="text-end border-top-0">
                ${transaction?.amount}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Rate</td>
              <td className="text-end border-top-0">
                {transaction?.exchangeRate}
              </td>
            </tr>
            <tr>
              <td className="border-top-0">Paid Amount in (GBP)</td>
              <td className="text-end border-top-0">
               <i className="fa fa-gbp"></i>
                {transaction?.paidAmount}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
}