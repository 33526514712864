import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { EntityServices } from "../../Services/EntityServices";
import { END_POINTS_PATH, APP_PAGES as APP_PAGES } from "../../routes/paths";
import { useState } from "react";
import { ITransaction } from "../../types/types";

function Reciept() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
    const services = new EntityServices(navigate);

  const [transaction, setTransaction] = useState({} as ITransaction);

  const getTransaction = async () => {
    var response = await services.GetList(
      `${END_POINTS_PATH.GetTransactions}/${id}`
    );

    return response;
  };

  useQuery([id], getTransaction, {
    onSuccess: (data) => setTransaction(data?.data as ITransaction),
  });

  // generate function to open new tab for printing
  const printReciept = () => {
    window.print();
  };

  return (
    <div className="hor-content main-content mt-0">
      <div className="side-app">
        <div className="main-container container">
          <div className="page-header">
            <div>
              <h4 className="">Reciept</h4>
            </div>
            <div className="ms-auto pageheader-btn d-print-none">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={APP_PAGES.Transactions}>Transactions</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reciept
                </li>
              </ol>
            </div>
          </div>

          <div className="card bg-white overflow-hidden">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <img
                    src="/assets/images/Dahabshiil.jpg"
                    alt="Dahabshiil logo"
                    height={110}
                    width={220}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex flex-column justify-content-end text-center">
                  <h4 className="mb-1">Mobile Top-up Reciept</h4>
                  <span className="text-muted">
                    Please keep this reciept for your records
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover mb-0 text-nowrap">
                    <tr>
                      <td>
                        <h4 className="fs-16">
                          Dahabshiil Transfer Services Ltd
                        </h4>
                        <div className="d-flex flex-column mb-0">
                          <span className="text-muted me-3">
                            <strong>Telephone:</strong> 020 7375 1110
                          </span>
                          <span className="text-muted">
                            <strong>Email: </strong>
                            <a href="mailto:" className="text-muted">
                              customer.support@dahabshiil.co.uk
                            </a>
                          </span>
                          <span className="text-muted me-3 ">
                            <strong>Address:</strong> 85 Sidney Street, London,
                            E1 2FN
                          </span>
                          <span className="text-muted">
                            <strong>Website:</strong> https://dahabshiil.co.uk
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column justify-content-end text-end">
                          <h3 className="fs-16 mb-2">
                            <strong>TTNO:</strong> {transaction?.ttno}
                          </h3>
                          <h3 className="fs-14">
                            <strong>Date:</strong>{" "}
                            {new Date(transaction.createdAt).toDateString()}
                          </h3>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-hover mb-0 text-nowrap ">
                    <tr>
                      <td>
                        <p className="h4">Sender :</p>
                        <address>
                          {transaction?.senderName}
                          <br />
                          {transaction?.senderPhone}
                          <br />
                          {transaction?.senderCity}
                          <br />
                          {transaction?.senderEmail}
                        </address>
                      </td>
                      <td className="text-end">
                        <p className="h4">Recipient:</p>
                        <address>
                          {transaction?.recipientName}
                          <br />
                          {transaction?.recipientPhone}
                          <br />
                          {transaction?.recipientCity}
                          <br />
                          {transaction?.recipientEmail}
                        </address>
                      </td>
                    </tr>
                  </table>

                  <div className="table-responsive push">
                    <table className="table table-bordered table-hover mb-0 text-nowrap border-bottom">
                      <tbody>
                        <tr className=" ">
                          <th>Country</th>
                          <th className="text-center">Operator</th>
                          <th className="text-end">Operator Category</th>
                          <th className="text-end">Product</th>
                          <th className="text-end">Amount</th>
                          <th className="text-end">Local Amount</th>
                          <th className="text-end">Rate</th>
                          <th className="text-end">Paid Amount</th>
                        </tr>
                        <tr>
                          <td>
                            <p className="font-w600 mb-1">
                              {transaction?.country}
                            </p>
                          </td>
                          <td className="text-center">
                            {transaction?.operatorName}
                          </td>
                          <td className="text-end">
                            {transaction?.productCategory}
                          </td>
                          <td className="text-end">
                            {transaction?.productName}
                          </td>
                          <td className="text-end">${transaction?.amount}</td>
                          <td className="text-end">
                            ${transaction?.localAmount}
                          </td>
                          <td className="text-end">
                            {transaction?.exchangeRate}
                          </td>
                          <td className="text-end">
                            <i className="fa fa-gbp"></i>{" "}
                            {transaction?.paidAmount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <div className="d-flex flex-row justify-content-between mt-3">
                    <div className="text-start fs-11 p-0">
                      <h4 className="fs-14 p-0">
                        Terms & Conditions for Value Added Services (VAS) –
                        Airtime and Mobile Data Top-Up Service
                      </h4>
                      <ol className="ps-2">
                        <li>
                          <b>Service Definition:</b> Airtime refers to credit
                          for calls, messages, and data. You can send airtime or
                          mobile data to another person’s phone. Intended for
                          personal use only. Availability may vary due to system
                          upgrades or outages.
                        </li>
                        <li>
                          <b>Service Usage:</b> By submitting a top-up request,
                          you agree to provide the correct mobile number and the
                          amount of airtime or data. Additional details may be
                          required to complete the transaction.
                        </li>
                        <li>
                          <b>Fees and Taxes:</b> Fees and taxes may apply
                          depending on the region. Taxes may be included in your
                          total or deducted from the recipient's airtime or
                          data.
                        </li>
                        <li>
                          <b>Delivery Time:</b> Airtime or data is typically
                          delivered within minutes but may experience delays due
                          to network issues or congestion.
                        </li>
                        <li>
                          <b>Your Responsibility:</b> Ensure that the provided
                          mobile number and top-up details are correct. We are
                          not liable for errors or incorrect information, and
                          top-ups cannot be reversed.
                        </li>
                        <li>
                          <b>Our Responsibility:</b> We process top-ups based on
                          the information you provide. Mobile operators are
                          responsible for service delivery once the top-up is
                          sent.
                        </li>
                        <li>
                          <b>Cancellations and Refunds:</b> Once a top-up is
                          processed, it cannot be canceled or refunded, except
                          as required by law.
                        </li>
                        <li>
                          <b>Transaction Limits:</b> Limits may be placed on the
                          number and value of top-ups. Any applicable limits
                          will be communicated before transaction completion.
                        </li>
                        <li>
                          <b>Liability:</b> Our liability is limited to the
                          value of the purchased airtime or data. We are not
                          responsible for delays, technical issues, or service
                          interruptions from third-party providers.
                        </li>
                        <li>
                          <b>Data Protection:</b> We handle personal data in
                          accordance with our privacy policy, which you agree to
                          when using this service.
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      onClick={printReciept}
                      className="btn btn-info mb-1 d-print-none"
                    >
                      <i className="si si-printer"></i> Print Reciept
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reciept;
