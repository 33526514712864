import { useContext } from "react";
import { Prev } from "react-bootstrap/esm/PageItem";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import { APP_PAGES, END_POINTS_PATH } from "../../routes/paths";

export default function MainReportPage() {

    const {role} = useContext(AuthContext)

  const reportsPrevilage = [
    {
      Name: "Transaction Report",
      Description:
        "This report shows the insights of all transactions made by the agents",
      Color: "bg-purple-light",
      Path: "/transaction-reports",
      Prev: ["Admin"],
      Hide: false,
    },
    {
      Name: "Agent Report",
      Description:
        "This report shows the insights of all agents registered on the platform",
      Color: "bg-primary",
      Path: APP_PAGES.AgentReport,
      Prev: ["Admin","Agent"],
      Hide: false,
    },
    {
      Name: "Customer Report",
      Description:
        "This report shows the insights of all customers registered on the platform",
      Color: "bg-warning",
      Path: "/customer-reports",
      Prev: ["Admin"],
      Hide: true,
    },
    {
      Name: "Country Report",
      Description:
        "This report shows the insights of all countries registered on the platform",
      Color: "bg-black-3",
      Path: "/country-reports",
      Prev: ["Admin"],
      Hide: true,
    },
    {
      Name: "Operator Report",
      Description:
        "This report shows the insights of all operators registered on the platform",
      Color: "bg-danger",
      Path: "/operator-reports",
      Prev: ["Admin"],
      Hide: true,
    },
    {
      Name: "Commission Report",
      Description:
        "This report shows the insights of all commissions made on the platform",
      Color: "bg-info",
      Path: "/commission-reports",
      Prev: ["Admin"],
      Hide: false,
    },
    {
      Name: "Payment Report",
      Description:
        "This report shows the insights of all payments made on the platform",
      Color: "bg-success",
      Path: "/payment-reports",
      Prev: ["Admin"],
      Hide: false,
    }
  ];

  return (
    <div className="hor-content main-content mt-0" style={{ zIndex: 400 }}>
      <div className="side-app">
        <div className="main-container  container">
          <div className="text-center my-6 border-bottom-2 bg-light-gray">
            <h4 className="fs-25">REPORTS</h4>
            <p>
              These reports are generated based on the transactions made by
              agents
            </p>
          </div>
          <div className="row">

            {reportsPrevilage.map((report) => {
                if(report.Prev.includes(role ?? "") && !report.Hide)
              return (
                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">{report.Name}</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p style={{ width: "290px" }}>
                          <span className="fs-13 text-muted ">
                            {report.Description}
                          </span>
                        </p>
                        <div
                          className={
                            "counter-icon dash box-shadow-info " + report.Color
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                      <div className="text-end border-top pt-3">
                        <Link
                          to={report.Path}
                          className={"btn btn-primary "}
                        >
                          View Report
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
