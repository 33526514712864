import { Link } from "react-router-dom";
import { APP_PAGES } from "../../routes/paths";
import { ITransaction } from "../../types/types";

interface Props {
  transactions: ITransaction[];
  sendAgain: (transaction: ITransaction) => void;
  excludeActions?: boolean;
  displayColumns?: string[];
}

function TransactionTable({
  transactions,
  excludeActions,
  displayColumns,
}: Props) {
  
  const distinctCountries = Array.from(new Set(transactions.map(transaction => transaction.country)));

  // soft distinctCountries by length
  distinctCountries.sort((a, b) => {
    return transactions.filter(t => t.country === b).length - transactions.filter(t => t.country === a).length;
  });

  const colors = ["bg-primary", "bg-secondary", "bg-success", "bg-danger", "bg-warning", "bg-info", "bg-dark"];
  return (
    <>
      <div className="row">
        {distinctCountries.map((transaction, index) => (
          <div className="col-md-3">
            <div className="card border-end border-start border-bottom shadow-sm border-top">
              <div className="card-body project-type-container projects px-4">
                <h4 className="text-16 m-0">{transaction}</h4>
                <div className="d-sm-flex align-items-center">
                  <div className="btn-list mx-4">
                    <span className={"text-17 text-white fw-700 px-3 py-2 radius-5 " + colors[index > colors.length ? 0 : index]}>
                      {transactions.filter(t => t.country === transaction).length}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <table
        id="project-table"
        className="table text-nowrap mb-0 table-bordered border-top border-bottom project-list-main"
      >
        <thead className="table-head">
          <tr>
            {displayColumns?.includes("S/N") && <th>S/N</th>}
            {displayColumns?.includes("Transaction ID") && (
              <th>Transaction ID</th>
            )}
            {displayColumns?.includes("Recipient") && <th>Recipient</th>}
            {displayColumns?.includes("Sender") && <th>Sender</th>}
            {displayColumns?.includes("Country") && <th>Country</th>}
            {displayColumns?.includes("Operator") && <th>Operator</th>}
            {displayColumns?.includes("Product Category") && (
              <th>Product Category</th>
            )}
            {displayColumns?.includes("Date") && <th>Date</th>}
            {displayColumns?.includes("Amount") && <th>Amount</th>}
            {displayColumns?.includes("PaidAmount") && <th>Paid Amount</th>}
            {displayColumns?.includes("Status") && <th>Status</th>}
            {!excludeActions && <th className="border-bottom-0">Action</th>}
          </tr>
        </thead>
        <tbody className="table-body">
          {transactions.length === 0 ? (
            <tr>
              <td
                colSpan={displayColumns && displayColumns?.length + 1}
                className="text-center"
              >
                No transaction history was found.
              </td>
            </tr>
          ) : (
            transactions.map((transaction, index) => (
              <tr key={transaction.transactionId}>
                {displayColumns?.includes("S/N") && <td>{index + 1}</td>}
                {displayColumns?.includes("Transaction ID") && (
                  <td>
                    <Link
                      to={
                        APP_PAGES.TransactionDetials + "/" + transaction?.ttno
                      }
                      className="float-start"
                    >
                      {transaction?.ttno}
                    </Link>
                  </td>
                )}
                {displayColumns?.includes("Recipient") && (
                  <td>
                    <Link
                      to={
                        APP_PAGES.TransactionDetials + "/" + transaction?.ttno
                      }
                      className="float-start"
                    >
                      {transaction?.recipientName}
                    </Link>
                  </td>
                )}
                {displayColumns?.includes("Sender") && (
                  <td>{transaction?.senderName}</td>
                )}
                {displayColumns?.includes("Country") && (
                  <td>{transaction?.country}</td>
                )}
                {displayColumns?.includes("Operator") && (
                  <td>{transaction?.operatorName}</td>
                )}
                {displayColumns?.includes("Product Category") && (
                  <td>{transaction?.productCategory}</td>
                )}
                {displayColumns?.includes("Date") && (
                  <td>
                    {new Date(transaction?.createdAt).toLocaleDateString()}
                  </td>
                )}
                {displayColumns?.includes("Amount") && (
                  <td>
                    {" "}
                    <i className="fa fa-dollar"></i> {transaction?.amount}
                  </td>
                )}
                {displayColumns?.includes("PaidAmount") && (
                  <td>
                    {" "}
                    <i className="fa fa-gbp"></i> {transaction?.paidAmount}
                  </td>
                )}
                {!excludeActions && (
                  <td>
                    <Link
                      to={APP_PAGES.Reciept + "/" + transaction?.ttno}
                      className="btn btn-primary"
                    >
                      <i className="fe fe-file-text"></i> Reciept
                    </Link>{" "}
                    {/* <TopUpAgain
                    transaction={transaction}
                    submit={() => sendAgain}
                  /> */}
                  </td>
                )}
              </tr>
            ))
          )}
          <tr>
            <td colSpan={displayColumns && displayColumns?.length + 1}>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-14 mb-0">Total Transactions</p>
                </div>
                <div>
                  <p className="text-14 fw-700 mb-0">{transactions.length}</p>
                </div>
                <div>
                  <p className="text-14 mb-0">Total Amount</p>
                </div>
                <div>
                  <p className="text-14 fw-700 mb-0">
                    <i className="fa fa-dollar"></i>{" "}
                    {(
                      transactions.reduce(
                        (acc, curr) => acc + curr.amount,
                        0
                      ) ?? 0
                    ).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p className="text-14 mb-0">Total Paid Amount</p>
                </div>
                <div>
                  <p className="text-14 fw-700 mb-0">
                    <i className="fa fa-gbp"></i>{" "}
                    {(
                      transactions.reduce(
                        (acc, curr) => acc + curr.paidAmount,
                        0
                      ) ?? 0
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default TransactionTable;
