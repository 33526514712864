import { Link } from "react-router-dom";
import { ITransaction } from "../../types/types";
import { APP_PAGES } from "../../routes/paths";

interface Props {
  transactions: ITransaction[];
}

export default function LatestTransactions({ transactions }: Props) {
  return (
    <div className="row my-4">
      <div className="col-xs-12 mb-3">
        <h4 className="card-title fw-semibold">Recent Transactions</h4>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12">
        <div className="card overflow-hidden">
          <div className="card-body p-0 customers mt-1">
            <div className="list-group py-1">
              {transactions.length === 0 ? (
                <div className="list-group-item border-0">
                  <div className="media mt-0 align-items-center">
                    <div className="transaction-icon">
                      <i className="fe fe-chevrons-right"></i>
                    </div>
                    <div className="media-body">
                      <p>No topup history was found.</p>
                    </div>
                  </div>
                </div>
              ) : (
                transactions.map((transaction, index) => (
                  <Link
                    to={APP_PAGES.TransactionDetials + "/" + transaction.ttno}
                    className="border-0"
                  >
                    <div className="list-group-item border-0">
                      <div className="media mt-0 align-items-center">
                        <div className="transaction-icon">
                          <i className="fe fe-chevrons-right"></i>
                        </div>
                        <div className="media-body">
                          <div className="d-flex align-items-center">
                            <div className="mt-0">
                              <h5 className="mb-1 fs-13 fw-normal text-dark">
                                {transaction.recipientName}
                              </h5>
                              <span className="mb-0 fs-12 border-end-1 pe-2 text-muted">
                                Top-up to{" "}
                                <span className="text-primary">
                                  {transaction.country}
                                </span>
                              </span>
                              <span className="mb-0 fs-12 ms-3 text-muted">
                                Top-up time {" "}
                                <span className="text-primary">
                                  {transaction.createdAt}
                                </span>
                              </span>
                            </div>
                            <span className="ms-auto fs-13">
                              <span className="float-end text-dark">
                                ${transaction?.amount}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
