import { ICountry, IOperator, ITransaction, Product } from "../../types/types";
import ExistedCustomerModel from "./ExistedCustomerModel";

interface Props {
  transaction: ITransaction;
  handleChange: (e: any) => void;
  onBlur: (name: string) => void;
  setTransaction: (transaction: ITransaction) => void;
  handleSelectChangeOperator: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSelectChangeCountry: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleOnBlurRecipeintNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChangeProductCategory: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  handleSelectChangeProducts: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleAmountChange: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  handleLocalAmountChange: (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => void;
  handleSenderPhoneBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handlePaidAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showExistedCustomerModel: (phone: string) => void;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  requiredFields: (
    | {
        name: string;
        value: string;
        errerMessage: string;
      }
    | {
        name: string;
        value: number;
        errerMessage: string;
      }
  )[];
  countries: ICountry[];
  countriesLoading: boolean;
  operators: IOperator[];
  operatorsLoading: boolean;
  productCategories: string[];
  productCategoriesLoading: boolean;
  products: Product[];
  senderPhone: string;
  productsLoading: boolean;
  isValidPhoneNumber: boolean | null;
  isValidPhoneNumberLoading: boolean;
  existedCustomerModel: boolean;
  amountConfigLoading: boolean;
  setCustomer: (customer: any) => void;
}

export default function TopupForm({
  transaction,
  handleChange,
  onBlur,
  setTransaction,
  setCustomer,
  handleSelectChangeOperator,
  handleSelectChangeCountry,
  handleSelectChangeProductCategory,
  handleSelectChangeProducts,
  handleOnBlurRecipeintNumber,
  handleAmountChange,
  handleLocalAmountChange,
  handleSenderPhoneBlur,
  handlePaidAmountChange,
  handleSelectChange,
  requiredFields,
  countries,
  countriesLoading,
  senderPhone,
  operators,
  operatorsLoading,
  productCategories,
  productCategoriesLoading,
  products,
  productsLoading,
  isValidPhoneNumber,
  amountConfigLoading,
  isValidPhoneNumberLoading,
  existedCustomerModel,
}: Props) {
  return (
    <div className="row pt-5 px-5">
      <span>
        <i>
          All fields marked with <b>*</b> are required.{" "}
        </i>
      </span>
      {/* Add Sender Form */}

      <div className="row p-5 border-bottom">
        <h5>Topup Information</h5>
        <div className="col-sm-12 col-md-12 col-xl-6">
          <div className="form-group">
            <label htmlFor="sender" className="form-label text-muted">
              Sender Name <span className="text-red">*</span>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              id="sender"
              placeholder="Sender Fullname"
              name={"senderName"}
              value={transaction.senderName}
              onChange={handleChange}
              onBlur={() => onBlur("senderName")}
            />
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "senderName")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-6">
          <div className="form-group">
            <label htmlFor="phone" className="form-label text-muted">
              Sender Phone <span className="text-red">*</span>
            </label>
            <div className="d-flex flex-row justify-content-between">
              <input
                type="number"
                className="form-control"
                id="phone"
                placeholder="Phone"
                name={"senderPhone"}
                value={transaction.senderPhone}
                onChange={handleChange}
                onBlur={handleSenderPhoneBlur}
              />
              {existedCustomerModel && (
                <ExistedCustomerModel
                  phone={senderPhone}
                  setCustomer={setCustomer}
                />
              )}
            </div>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "senderPhone")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        {/* Country */}
        <div className="col-sm-12 col-md-12 col-xl-4">
          <div className="form-group">
            <label htmlFor="country" className="form-label text-muted">
              Country:
            </label>
            <select
              className="form-control select2-show-search"
              id="country"
              name="countryISO2"
              value={transaction.countryISO2}
              onChange={handleSelectChangeCountry}
              onBlur={() => onBlur("countryISO2")}
            >
              {countriesLoading ? (
                <option label="Loading"></option>
              ) : (
                <option label="Choose one"></option>
              )}
              {countries.map((item, index) => {
                return (
                  <option key={index} value={item?.Iso2}>
                    {item.Name}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "countryISO2")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        {/* Operators */}
        <div className="col-sm-12 col-md-12 col-xl-4">
          <div className="form-group">
            <label htmlFor="operator" className="form-label text-muted">
              Operators:
            </label>
            <select 
              className={"form-control select2-show-search  disabled"}
              id="operator"
              disabled={operators.length === 0 || operatorsLoading}
              name="operatorId"
              value={transaction.operatorId}
              onChange={handleSelectChangeOperator}
              onBlur={() => onBlur("operatorId")}
            >
              {operatorsLoading ? (
                <option label="Loading"></option>
              ) : (
                <option label="Choose one"></option>
              )}
              {operators.map((item, index) => {
                return (
                  <option key={index} value={item.Id}>
                    {item.Name}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "operatorId")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        {/* Product Category */}
        <div className="col-sm-12 col-md-12 col-xl-4">
          <div className="form-group">
            <label htmlFor="ProductCategory" className="form-label text-muted">
              Product Category:
            </label>
            <select
              className={
                "form-control select2-show-search " +
                (products === null ? "disabled" : "")
              }
              id="ProductCategory"
              name="productCategory"
              disabled={productCategories.length === 0 || productsLoading}
              value={transaction.productCategory}
              onChange={handleSelectChangeProductCategory}
              onBlur={() => onBlur("productCategory")}
            >
              {productsLoading ? (
                <option label="Loading"></option>
              ) : (
                <option label="Choose one"></option>
              )}
              {productCategories.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "productCategory")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        {/* Product */}
        <div className="col-sm-12 col-md-12 col-xl-4">
          <div className="form-group">
            <label htmlFor="product" className="form-label text-muted">
              Product:
            </label>
            <select
              className="form-control select2-show-search"
              id="product"
              name="productId"
              disabled={products.length === 0 || productCategoriesLoading}
              value={transaction.productId}
              onChange={handleSelectChangeProducts}
              onBlur={() => onBlur("productId")}
            >
              {productCategoriesLoading ? (
                <option label="Loading"></option>
              ) : (
                <option label="Choose one"></option>
              )}
              {products.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "productId")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        {transaction.priceType !== "fixed" ? (
          <div className="col-sm-12 col-md-12 col-xl-2">
            <div className="form-group">
              <label htmlFor="localAmount" className="form-label text-muted">
                Operator Amount{" "}
              </label>
              <div className="d-flex flex-row">
                <i
                  className=" py-2 px-3  border  bg-gray-100"
                  style={{ borderRadius: "5px 0 0 5px", borderRight: "0" }}
                >
                  {transaction.currency && transaction?.currency}
                </i>
                <input
                  type="number"
                  className="form-control"
                  style={{ borderRadius: "0 5px 5px 0", borderLeft: "0" }}
                  id="localAmount"
                  name={"localAmount"}
                  defaultValue={0}
                  disabled={
                    transaction.priceType === "fixed" ||
                    transaction.productId === undefined ||
                    transaction.productId === "" ||
                    transaction.productId === null
                  }
                  onChange={handleLocalAmountChange}
                  onBlur={() => onBlur("localAmount")}
                />
              </div>
              <span className="text-danger">
                {
                  requiredFields.find((item) => item.name === "localAmount")
                    ?.errerMessage
                }
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-sm-12 col-md-12 col-xl-2">
          <div className="form-group">
            <label htmlFor="amount" className="form-label text-muted">
              Amount in USD :
            </label>
            <div className="d-flex flex-row">
              <i
                className="fa fa-dollar  py-2 px-3  border  bg-gray-100"
                style={{ borderRadius: "5px 0 0 5px", borderRight: "0" }}
              ></i>
              {transaction.priceType && transaction.priceType !== "fixed" ? (
                <input
                  type="number"
                  className="form-control"
                  style={{ borderRadius: "0 5px 5px 0", borderLeft: "0" }}
                  disabled={transaction.priceType === "fixed"}
                  id="amount"
                  placeholder="Amount"
                  name="amount"
                  defaultValue={transaction.amount}
                  onBlur={handleAmountChange}
                />
              ) : (
                <input
                  type="number"
                  className="form-control"
                  style={{ borderRadius: "0 5px 5px 0", borderLeft: "0" }}
                  disabled={
                    transaction.priceType === "fixed" ||
                    transaction.productId === undefined ||
                    transaction.productId === "" ||
                    transaction.productId === null
                  }
                  id="amount"
                  placeholder="Amount"
                  name="amount"
                  value={transaction.amount}
                  onBlur={handleAmountChange}
                />
              )}
            </div>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "amount")
                  ?.errerMessage
              }
            </span>
            {transaction.priceType && transaction.priceType !== "fixed" ? (
              <small className="text-muted"> </small>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-xl-2">
          <div className="form-group">
            <label htmlFor="exchangeRate" className="form-label text-muted">
              Rate:
            </label>
            <input
              type="number"
              className="form-control"
              id="exchangeRate"
              placeholder="0"
              name="exchangeRate"
              disabled={true}
              value={
                !amountConfigLoading ? transaction.exchangeRate : "Loading"
              }
              onChange={handleChange}
              onBlur={() => onBlur("exchangeRate")}
            />
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "exchangeRate")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-xl-2">
          <div className="form-group">
            <label htmlFor="paidAmount" className="form-label text-muted">
              Paid Amount in GBP :
            </label>
            <div className="d-flex flex-row">
              <i
                className="fa fa-gbp py-2 px-3 border  bg-gray-100"
                style={{ borderRadius: "5px 0 0 5px", borderRight: "0" }}
              ></i>
              <input
                type="number"
                className="form-control"
                style={{ borderRadius: "0 5px 5px 0", borderLeft: "0" }}
                id="paidAmount"
                itemType="number"
                placeholder="0"
                name="paidAmount"
                disabled={
                  transaction.priceType === "fixed" ||
                  transaction.productId === undefined ||
                  transaction.productId === "" ||
                  transaction.productId === null
                }
                value={
                  !amountConfigLoading ? transaction.paidAmount : "Loading"
                }
                onChange={handlePaidAmountChange}
                onBlur={() => onBlur("paidAmount")}
              />
            </div>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "paidAmount")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-xl-5">
          <div className="form-group">
            <label htmlFor="recipient" className="form-label text-muted">
              Recipient Fullname <span className="text-red">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="recipient"
              placeholder="Recipient fullname"
              name={"recipientName"}
              value={transaction.recipientName}
              onChange={handleChange}
              onBlur={() => onBlur("recipientName")}
            />
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "recipientName")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>

        {/* Phone number */}
        <div className="col-sm-12 col-md-12 col-xl-5">
          <div className="form-group">
            <label htmlFor="phone" className="form-label text-muted">
              Recipient Phone Number <span className="text-red">*</span>
              {isValidPhoneNumberLoading ? <small>Validating...</small> : null}
            </label>
            <input
              type="text"
              className="form-control"
              style={
                isValidPhoneNumber === null
                  ? {}
                  : isValidPhoneNumber
                  ? { border: "2px solid #28a745" }
                  : { border: "2px solid #dc3545" }
              }
              id="phone"
              placeholder="Phone Number"
              name={"recipientPhone"}
              value={transaction.recipientPhone}
              onBlur={handleOnBlurRecipeintNumber}
              onChange={handleChange}
            />
            <small
              style={
                isValidPhoneNumber === null
                  ? { color: "#707070" }
                  : isValidPhoneNumber
                  ? { color: "#28a745" }
                  : { color: "#dc3545" }
              }
            >
              {isValidPhoneNumber === null
                ? "Enter the phone number with the country code e.g 252XXXXXXXXX"
                : isValidPhoneNumber
                ? "Phone number is valid"
                : requiredFields.find((item) => item.name === "recipientPhone")
                    ?.errerMessage
                ? requiredFields.find((item) => item.name === "recipientPhone")
                    ?.errerMessage
                : "Phone number is invalid"}
            </small>
          </div>
        </div>

        {/* Product Category */}
        <div className="col-sm-12 col-md-12 col-xl-2">
          <div className="form-group">
            <label htmlFor="paymentMethod" className="form-label text-muted">
              Payment Method:
            </label>
            <select
              className={"form-control select2-show-search"}
              id="paymentMethod"
              name="paymentMethod"
              value={transaction.paymentMethod}
              onChange={handleSelectChange}
              onBlur={() => onBlur("paymentMethod")}
            >
              <option label="Choose one"></option>
              <option value="Cash">Cash</option>
              <option value="Card">Card</option>
            </select>
            <span className="text-danger">
              {
                requiredFields.find((item) => item.name === "paymentMethod")
                  ?.errerMessage
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
