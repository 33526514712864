import React, { useEffect, useState } from "react";
import { IAgent, IResponse } from "../../types/types";
import { END_POINTS_PATH } from "../../routes/paths";
import ShowSnackbar from "../../Services/snackbar";
import { EntityServices } from "../../Services/EntityServices";
import { useNavigate } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  isEdit: boolean;
  existedAgent?: IAgent;
  refreshAgents: () => void;
}

export default function AgentForm({
  id,
  title,
  isEdit,
  existedAgent,
  refreshAgents,
}: Props) {
  const navigate = useNavigate();
    const services = new EntityServices(navigate);

  const [agent, setAgent] = useState({} as IAgent);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAgent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isEdit === true && existedAgent !== undefined) {
      setAgent(existedAgent as IAgent);
    }
  }, [existedAgent]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setAgent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    var response: IResponse | null = null;

    if (isEdit === true) {
      response = await services.Put(END_POINTS_PATH.Agents, agent);
    } else {
      response = await services.Post(END_POINTS_PATH.Agents, agent);
    }

    if (response === null) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    refreshAgents();
  };

  return (
    <>
      <button
        className="btn btn-primary me-2 btn-sm"
        data-bs-toggle="modal"
        data-bs-target={"#Client_" + id}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-inner-icn text-white"
          enable-background="new 0 0 24 24"
          viewBox="0 0 24 24"
        >
          <path d="M16,11.5h-3.5V8c0-0.276123-0.223877-0.5-0.5-0.5S11.5,7.723877,11.5,8v3.5H8c-0.276123,0-0.5,0.223877-0.5,0.5s0.223877,0.5,0.5,0.5h3.5v3.5005493C11.5001831,16.2765503,11.723999,16.5001831,12,16.5h0.0006104C12.2765503,16.4998169,12.5001831,16.276001,12.5,16v-3.5H16c0.276123,0,0.5-0.223877,0.5-0.5S16.276123,11.5,16,11.5z M12,2C6.4771729,2,2,6.4771729,2,12s4.4771729,10,10,10c5.5202026-0.0062866,9.9937134-4.4797974,10-10C22,6.4771729,17.5228271,2,12,2z M12,21c-4.9705811,0-9-4.0294189-9-9s4.0294189-9,9-9c4.9682617,0.0056152,8.9943848,4.0317383,9,9C21,16.9705811,16.9705811,21,12,21z" />
        </svg>
        {title}
      </button>
      <div
        className="modal fade"
        id={"Client_" + id}
        tabIndex={-1}
        aria-labelledby="editClient"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title} {id }
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Agent edit form */}
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter username"
                  name="username"
                  value={agent?.username}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Role
                </label>
                <select
                  className="form-select"
                  id="role"
                  name="Role"
                  value={agent?.role}
                  onChange={handleSelectChange}
                >
                  <option value="Admin">Admin</option>
                  <option value="Agent">Agent</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button onClick={submit} className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
