import { ITransaction } from "../../types/types";

interface props {
  transaction: ITransaction;
  IsRecipient: boolean;
}

function IdentityInfo({transaction, IsRecipient}: props) {
  return IsRecipient ? (
    <div className="card bg-white">
      <div className="card-header border-bottom">
        <h4 className="card-title">Recipient Information</h4>
      </div>
      <div className="card-body">
        <ul className="task-list">
          <li>
            <i className="task-icon bg-primary"></i>
            <h6>Recipient Name</h6>
            <p className="text-muted fs-12">
              {transaction?.recipientName}
            </p>
          </li>
          <li>
            <i className="task-icon bg-secondary"></i>
            <h6>Recipient Phone</h6>
            <p className="text-muted fs-12">
              {transaction?.recipientPhone}
            </p>
          </li>
          <li>
            <i className="task-icon bg-orange"></i>
            <h6>Recipient Email</h6>
            <p className="text-muted fs-12">
              {transaction?.recipientEmail}
            </p>
          </li>
          <li>
            <i className="task-icon bg-teritary"></i>
            <h6>Recipient Address</h6>
            <p className="text-muted fs-12">
              {transaction?.recipientAddress}
            </p>
          </li>
          <li>
            <i className="task-icon bg-custom-yellow"></i>
            <h6>Recipient City</h6>
            <p className="text-muted fs-12">
              {transaction?.recipientCity}
            </p>
          </li>
        </ul>
      </div>
    </div>
  ) : (
    <div className="card bg-white">
      <div className="card-header border-bottom">
        <h4 className="card-title">Sender Information</h4>
      </div>
      <div className="card-body">
        <ul className="task-list">
          <li>
            <i className="task-icon bg-primary"></i>
            <h6>Sender Name</h6>
            <p className="text-muted fs-12">{transaction?.senderName}</p>
          </li>
          <li>
            <i className="task-icon bg-secondary"></i>
            <h6>Sender Phone</h6>
            <p className="text-muted fs-12">{transaction?.senderPhone}</p>
          </li>
          <li>
            <i className="task-icon bg-orange"></i>
            <h6>Sender Email</h6>
            <p className="text-muted fs-12">{transaction?.senderEmail}</p>
          </li>
          <li>
            <i className="task-icon bg-teritary"></i>
            <h6>Sender Address</h6>
            <p className="text-muted fs-12">
              {transaction?.senderAddress}
            </p>
          </li>
          <li>
            <i className="task-icon bg-custom-yellow"></i>
            <h6>Sender City</h6>
            <p className="text-muted fs-12">{transaction?.senderCity}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default IdentityInfo;
