import { useEffect, useState } from "react";
import { ICustomer } from "../../types/types";
import { EntityServices } from "../../Services/EntityServices";
import { END_POINTS_PATH } from "../../routes/paths";
import { useNavigate } from "react-router-dom";

interface Props {
  phone: string;
  setCustomer: (customer: ICustomer) => void;
}

export default function ExistedCustomerModel({ phone, setCustomer }: Props) {
    const navigate = useNavigate();
    const services = new EntityServices(navigate);

  const [customers, setCustomers] = useState([] as ICustomer[]);

  const [originalCustomers, setOriginalCustomers] = useState([] as ICustomer[]);

  useEffect(() => {
    getCustomers();
  }, [phone]);

  const getCustomers = async () => {
    var response = await services.GetList(
      END_POINTS_PATH.GetCustomers + `?phoneNumber=${phone}`
    );

    if (response === null) {
      return;
    }

    if (response?.statusCode !== 200) {
      return;
    }

    setCustomers(response.data);
    setOriginalCustomers(response.data);
  };

  const searchCustomer = (e: any) => {

    if (e.target.value === "") {
      setCustomers(originalCustomers);
      return;
    }

    const value = e.target.value.toLowerCase();
    
    const filteredCustomer = originalCustomers.filter((customer) => {
      return (
        customer.fullname.toLowerCase().includes(value) ||
        customer.phone.includes(value)
      );
    });

    setCustomers(filteredCustomer);
  };


  return (
    <>
      <button
        type="button"
        className="btn btn-outline-primary ms-2"
        data-bs-toggle="modal"
        title="Select sender from existing customers"
        data-bs-target="#existedCustomerModal"
        style={{ width: "70%" }}
      >
        Select Customer
      </button>
      <div
        className="modal fade custom-modal"
        id="existedCustomerModal"
        tabIndex={200}
        aria-labelledby="existedCustomerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="existedCustomerModalLabel">
                Existed Customer
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          onChange={searchCustomer}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.fullname}</td>
                                <td>{item.phone}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      setCustomer(item);
                                      document.getElementById(
                                        "existedCustomerModal"
                                      )?.click();
                                    }}
                                  >
                                    Select
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
