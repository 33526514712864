import { ReactNode, useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Login from "../pages/LogInPage";
import { AuthContext } from "./authContext";
import { getSession, isValidToken } from "./utils";
import axios from "../utils/axios";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { authenticated, setAuthenticated } = useContext(AuthContext);

  const { pathname } = useLocation();

  if (!isValidToken()) {
    setAuthenticated(false);
  } else {
    setAuthenticated(true);
  }

  if (!authenticated) {
    return <Login />;
  } else {
    if (pathname.toLocaleLowerCase().includes("login")) {
      return <Navigate to={"/"} replace />;
    }
  }

  return <>{children}</>;
}
