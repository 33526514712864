import { ITransaction } from "../../types/types";
import TopConfirmModel from "./topConfirmModel";

interface Props {
  transaction: ITransaction;
  currentStep: number;
  formSteps: string[];
  nextButtonDisabled: boolean;
  isSubmiting: boolean;
  submitTransaction: () => void;
  nextStep: () => void;
  previousStep: () => void;
  toggleSaveAsShortcuts: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TopupFormActions({
  transaction,
  currentStep,
  formSteps,
  nextButtonDisabled,
  isSubmiting,
  submitTransaction,
  nextStep,
  previousStep,
  toggleSaveAsShortcuts,
  handleChange,
}: Props) {
  return (
    <div className="d-flex flex-row justify-content-between p-5">
      <div className="custom-switch float-end mb-0">
        <div
          className="main-toggle-group style1 me-2"
          onClick={toggleSaveAsShortcuts}
        >
          <div
            className={transaction.saveAsShortcut ? "toggle on" : "toggle off"}
          >
            <span></span>
          </div>
        </div>
        <a href="#" className="text-14 mb-2 text-dark">
          Save as shortcut
        </a>
      </div>

      <p>Please review the above information before you submit it.</p>

      {/* Next or Finish button */}

      <div className="btn-list text-end">
        {/* prevoiuse button */}

        <button
          type="button"
          className={"btn btn-primary " + (currentStep === 0 ? "disabled" : "")}
          onClick={previousStep}
        >
          Previous
        </button>
        {/* Next or Finish button*/}
        {currentStep === formSteps.length - 1 ? (
          <TopConfirmModel
            isSubmiting={isSubmiting}
            submitTransaction={submitTransaction}
            handleChange={handleChange}
            transaction={transaction}
          />
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            disabled={nextButtonDisabled}
            onClick={nextStep}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
