import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { EntityServices } from "../../Services/EntityServices";
import { END_POINTS_PATH, APP_PAGES as APP_PAGES } from "../../routes/paths";
import { useState } from "react";
import { ITransaction } from "../../types/types";
import IdentityInfo from "./IdentityInfo";
import TransactionTable from "../transactions/transactionTable";
import TopUpAgain from "../topUp/topupAgain";
import ShowSnackbar from "../../Services/snackbar";

function TransactionDetails() {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const services = new EntityServices(navigate);

  const [transaction, setTransaction] = useState({} as ITransaction);

  const [transactions, setTransactions] = useState([] as ITransaction[]);

  const getTransaction = async () => {
    var response = await services.GetList(
      `${END_POINTS_PATH.GetTransactions}/${id}`
    );

    if (response?.statusCode !== 200) return;

    if (response?.data === undefined || response?.data === null) return;

    if (
      response?.data?.senderName === undefined ||
      response?.data?.senderName === null
    )
      return response;

    fetchTransactions(response?.data?.senderName ?? "");
    return response;
  };

  const fetchTransactions = async (senderName: String) => {
    var response = await services.GetList(
      END_POINTS_PATH.GetSenderTransactions + "/" + (senderName ?? "")
    );
    if (response?.statusCode === 200) {
      setTransactions(response.data);
    }
  };

  useQuery([id], getTransaction, {
    onSuccess: (data) => setTransaction(data?.data as ITransaction),
  });

  const sendAgain = async () => {
    const topupRequest = { TransactionId: transaction.ttno };

    var response = await services.Post(END_POINTS_PATH.TopAgain, topupRequest);

    if (response?.statusCode === 200) {
      ShowSnackbar("Transaction sent successfully", "success");
      return;
    }

    ShowSnackbar("Transaction failed", "error");
  };

  return (
    <div className="hor-content main-content mt-0">
      <div className="side-app">
        <div className="main-container container">
          <div className="page-header">
            <div>
              <h4 className="">Transaction Details</h4>
            </div>
            <div className="ms-auto pageheader-btn">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={APP_PAGES.Transactions}>Transactions</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="btn-list text-end mb-3">
                <TopUpAgain
                  transaction={transaction}
                  submit={() => sendAgain()}
                />

                <Link
                  to={APP_PAGES.Reciept + "/" + transaction?.ttno}
                  className="btn btn-outline-info"
                >
                  <i className="fe fe-file-text"></i>
                  Reciept
                </Link>
              </div>
              <div className="card bg-white overflow-hidden">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl col-md-12">
                      <div className="d-flex flex-row justify-content-between mb-3">
                        <div>
                          <h6 className="text-primary fs-14">Sender</h6>
                          <h4 className="my-2 card-title">
                            {transaction?.senderName}
                            <span className="text-primary p-1 bg-light fs-12 mx-2">
                              {transaction?.senderEmail || "No Email Provided"}
                            </span>
                          </h4>
                          <div className="d-flex flex-row">
                            <span className="text-muted border-end pe-2 fs-12 float-start mt-1">
                              <i className="fe fe-phone fs-13"></i>{" "}
                              {transaction?.senderPhone}
                            </span>

                            <span className="text-muted mx-3 pe-2 fs-12 float-start mt-1">
                              <i className="fe fe-map-pin fs-13"></i>{" "}
                              {transaction?.senderAddress ||
                                "No Address Provided"}
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex flex-column">
                            <div className="text-center">
                              <h6 className="text-primary fs-14">#TTNO</h6>
                              <h4 className="my-2">{transaction?.ttno}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer p-0">
                  <div className="tabs-menu4 py-5 project-menu">
                    <nav className="nav border-bottom px-4 d-block d-lg-flex flex-2">
                      <a
                        className="nav-link border py-1 mb-2 mb-lg-0 br-lg-5 mx-1 mx-md-1 active"
                        data-bs-toggle="tab"
                        href="#tab_1"
                      >
                        Overview
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="tab-content">
                <div className="tab-pane active" id="tab_1">
                  <div className="row">
                    <div className="col-xl-9 col-lg-12">
                      <div className="card bg-white">
                        <div className="card-header border-bottom d-block">
                          <div className="row align-items-center">
                            <div className="col">
                              <h6 className="text-primary fs-14">Recipient</h6>
                              <h4 className="mb-1">
                                {transaction?.recipientName}
                              </h4>
                              <div className="d-sm-flex d-block">
                                <div className="text-muted pe-2 project-date">
                                  {transaction?.createdAt}
                                </div>
                                <div className="text-muted ms-3 px-2 project-status">
                                  {transaction?.country}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-white">
                        <div className="card-body">
                          <div className="row prjct-details">
                            <div className="col-xl-3 col-lg-12">
                              <div className="text-start px-2 border py-5 br-5 details">
                                <div>
                                  <span className="m-0 text-muted">
                                    <i className="fe fe-flag"></i> Country
                                  </span>
                                </div>
                                <h5 className="mt-3 text-success">
                                  {transaction?.country}
                                </h5>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-12">
                              <div className="text-start px-2  border py-5 br-5 details">
                                <div>
                                  <span className="m-0 text-muted">
                                    <i className="fe fe-layout"></i> Recipient
                                    Operator
                                  </span>
                                </div>
                                <h5 className="mt-3 text-success">
                                  {transaction?.operatorName}
                                </h5>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-12">
                              <div className="text-start px-2  border py-5 br-5 details">
                                <div>
                                  <span className="m-0 text-muted">
                                    <i className="fe fe-layers"> </i>
                                    Product Category
                                  </span>
                                </div>
                                <h5 className="mt-3 text-success">
                                  {transaction?.productCategory}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className="row prjct-details">
                            <div className="col-xl-6 col-lg-12">
                              <div className="text-start px-2  border py-5 br-5 details">
                                <div>
                                  <span className="m-0 text-muted">
                                    <i className="fe fe-server"> </i>
                                    Product
                                  </span>
                                </div>
                                <h5 className="mt-3 text-success">
                                  {transaction?.productName}
                                </h5>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-12">
                              <div className="text-start px-2  border py-5 br-5 details">
                                <div>
                                  <span className="m-0 text-muted">
                                    <i className="fe fe-phone"></i> Recipient
                                    Phone
                                  </span>
                                </div>
                                <h5 className="mt-3 text-success">
                                  {transaction?.recipientPhone}
                                </h5>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-12">
                              <div className="text-center px-2  border py-5 br-5 details">
                                <div>
                                  <span className="m-0 text-muted">
                                    <i className="fe fe-dollar-sign"></i>
                                    Amount
                                  </span>
                                </div>
                                <h5 className="mt-3 text-danger fw-600">
                                  ${transaction?.amount}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row my-5">
                            <h4 className="text-primary mb-5">
                              Sender Transaction History
                            </h4>
                            <TransactionTable
                              transactions={transactions}
                              sendAgain={sendAgain}
                              displayColumns={[
                                "Transaction ID",
                                "Recipient",
                                "Country",
                                "Operator",
                                "Product Category",
                                "Date",
                                "Amount",
                              ]}
                              excludeActions={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-12">
                      <IdentityInfo
                        transaction={transaction}
                        IsRecipient={false}
                      />
                      <IdentityInfo
                        transaction={transaction}
                        IsRecipient={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
