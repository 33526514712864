import { useEffect, useState } from "react";
import { END_POINTS_PATH } from "../../routes/paths";
import { EntityServices } from "../../Services/EntityServices";
import ShowSnackbar from "../../Services/Services/snackbar";
import { IDashboardData, IShortcuts, ITransaction } from "../../types/types";
import DashboardCard from "./dashboardCard";
import LatestTransactions from "./latestTransactions";
import Shortcuts from "./shorcuts";
import { useNavigate } from "react-router-dom";

export default function Home() {

    const navigate = useNavigate();
    const services = new EntityServices(navigate);

  const [dashboardData, setDashboardData] = useState({} as IDashboardData);

  const [latestTransactions, setLatestTransactions] = useState([] as ITransaction[]);

  const [shortcuts, setShortcuts] = useState([] as IShortcuts[]);

  useEffect(() => {
    getDashboardData();
    getLatestTransactions();
    getShorcuts();
  }, []);

  const getShorcuts = async () => {
    var response = await services.GetList(END_POINTS_PATH.GetShortcuts);

    if(response === null){
      ShowSnackbar('An error occured', 'error');
      return
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar(response?.error ?? "Failed", 'error');
      return;
    }

    setShortcuts(response.data);
  }

  const getDashboardData = async () => {
    var response = await services.GetSingle(END_POINTS_PATH.GetDashboardData);

    if(response === null){
      ShowSnackbar('An error occured', 'error');
      return
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar(response?.error ?? "Failed", 'error');
      return;
    }

    setDashboardData(response.data);
  }

  const getLatestTransactions = async () => {
    var response = await services.GetList(END_POINTS_PATH.GetLatestTransactions);

    if(response === null){
      ShowSnackbar('An error occured', 'error');
      return
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar(response?.error ?? "Failed", 'error');
      return;
    }

    setLatestTransactions(response.data);
  }


    return (
      <div className="hor-content main-content mt-0">
        <div className="side-app">
          <div className="main-container container">
            <div className="page-header px-4">
              <div>
                <h1 className="page-title">Home</h1>
              </div>
              <div className="ms-auto pageheader-btn">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0);">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Summary
                  </li>
                </ol>
              </div>
            </div>

            <DashboardCard dashboardData={dashboardData} />
            <Shortcuts shortcuts={shortcuts} />            
            <LatestTransactions transactions={latestTransactions} />
          </div>
        </div>
      </div>
    );
  }
