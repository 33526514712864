import { Link, useNavigate } from "react-router-dom";
import { END_POINTS_PATH, APP_PAGES } from "../../routes/paths";
import { ITransaction, ITransactionFilter } from "../../types/types";
import { useEffect, useState } from "react";
import { EntityServices } from "../../Services/EntityServices";
import TransactionTable from "./transactionTable";
import ShowSnackbar from "../../Services/snackbar";
import FilterTranscation from "./filterTranscation";

function TransactionsList() {
  const navigate = useNavigate();
    const services = new EntityServices(navigate);

  var _transactions = [] as ITransaction[];

  const [transactions, setTransactions] = useState(_transactions);

  const _transactionFilter : ITransactionFilter = { amount: 0, from: new Date().toISOString().split("T")[0], receiver: "", sender: "", to: new Date().toISOString().split("T")[0] };
  const [transactionFilter, SetTransactionFilter] = useState(_transactionFilter);

  const filterTransactions = async () => {
    var response = await services.Post(
      END_POINTS_PATH.FilterTransactions,
      transactionFilter
    );

    if (response?.statusCode === 200) {
      setTransactions(response.data);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    var response = await services.GetList(END_POINTS_PATH.GetTransactions);
    if (response?.statusCode === 200) {
      setTransactions(response.data);
    }
  };

  const sendAgain = async (transaction: ITransaction) => {
    const topupRequest = { TransactionId: transaction.ttno };

    var response = await services.Post(END_POINTS_PATH.TopAgain, topupRequest);

    if (response?.statusCode === 200) {
      ShowSnackbar("Transaction sent successfully", "success");
      return;
    }

    ShowSnackbar("Transaction failed", "error");
  };

  return (
    <div className="hor-content main-content mt-0">
      <div className="side-app">
        <div className="main-container container">
          {/* Transactions Table */}

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body px-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="m-0">All Transactions</h4>
                    <div className="btn-list">
                      <Link
                        to={APP_PAGES.TransactionsList}
                        className="btn btn-outline-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-inner-icn"
                          enable-background="new 0 0 24 24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M21.5,13h-8.0005493C13.2234497,13.0001831,12.9998169,13.223999,13,13.5v8.0005493C13.0001831,21.7765503,13.223999,22.0001831,13.5,22h8.0006104C21.7765503,21.9998169,22.0001831,21.776001,22,21.5v-8.0006104C21.9998169,13.2234497,21.776001,12.9998169,21.5,13z M21,21h-7v-7h7V21z M10.5,2H2.4993896C2.2234497,2.0001831,1.9998169,2.223999,2,2.5v8.0005493C2.0001831,10.7765503,2.223999,11.0001831,2.5,11h8.0006104C10.7765503,10.9998169,11.0001831,10.776001,11,10.5V2.4993896C10.9998169,2.2234497,10.776001,1.9998169,10.5,2z M10,10H3V3h7V10z M10.5,13H2.4993896C2.2234497,13.0001831,1.9998169,13.223999,2,13.5v8.0005493C2.0001831,21.7765503,2.223999,22.0001831,2.5,22h8.0006104C10.7765503,21.9998169,11.0001831,21.776001,11,21.5v-8.0006104C10.9998169,13.2234497,10.776001,12.9998169,10.5,13z M10,21H3v-7h7V21z M21.5,2h-8.0005493C13.2234497,2.0001831,12.9998169,2.223999,13,2.5v8.0005493C13.0001831,10.7765503,13.223999,11.0001831,13.5,11h8.0006104C21.7765503,10.9998169,22.0001831,10.776001,22,10.5V2.4993896C21.9998169,2.2234497,21.776001,1.9998169,21.5,2z M21,10h-7V3h7V10z" />
                        </svg>
                      </Link>
                      <a className="btn btn-primary" href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-inner-icn text-white"
                          enable-background="new 0 0 24 24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M3.5,12C3.223877,12,3,12.223877,3,12.5S3.223877,13,3.5,13S4,12.776123,4,12.5S3.776123,12,3.5,12z M6.5,8h15C21.776123,8,22,7.776123,22,7.5S21.776123,7,21.5,7h-15C6.223877,7,6,7.223877,6,7.5S6.223877,8,6.5,8z M3.5,17C3.223877,17,3,17.223877,3,17.5S3.223877,18,3.5,18S4,17.776123,4,17.5S3.776123,17,3.5,17z M21.5,12h-15C6.223877,12,6,12.223877,6,12.5S6.223877,13,6.5,13h15c0.276123,0,0.5-0.223877,0.5-0.5S21.776123,12,21.5,12z M3.5,7C3.223877,7,3,7.223877,3,7.5S3.223877,8,3.5,8S4,7.776123,4,7.5S3.776123,7,3.5,7z M21.5,17h-15C6.223877,17,6,17.223877,6,17.5S6.223877,18,6.5,18h15c0.276123,0,0.5-0.223877,0.5-0.5S21.776123,17,21.5,17z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mx-0 px-0">
              <FilterTranscation
                headerText="Transactions"
                filterTransactions={filterTransactions}
                SetTransactionFilter={SetTransactionFilter}
                transactionFilter={transactionFilter}
              />
            </div>
            <div className="col-12 col-sm-12 bg-white shadow-sm">
              <div className="card">
                <div className="card-body project-list-table-container">
                  <div className="table-responsive">
                    <TransactionTable
                      transactions={transactions}
                      sendAgain={sendAgain}
                      displayColumns={[
                        "S/N",
                        "Transaction ID",
                        "Recipient",
                        "Sender",
                        "Country",
                        "Operator",
                        "Product Category",
                        "Date",
                        "Amount",
                        "PaidAmount",
                      ]}
                      excludeActions={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* End Transactions Table */}
        </div>
      </div>
    </div>
  );
}

export default TransactionsList;
