import { useState, useEffect } from "react";
import { END_POINTS_PATH } from "../../routes/paths";
import { EntityServices } from "../../Services/EntityServices";
import ShowSnackbar from "../../Services/snackbar";
import { ITransaction, ITransactionFilter } from "../../types/types";
import ReportTable from "./reportTable";
import { useNavigate } from "react-router-dom";

export default function TansactionReport() {
    const navigate = useNavigate();
    const services = new EntityServices(navigate);

  var _transactions = [] as ITransaction[];

  const [transactions, setTransactions] = useState(_transactions);

  const _transactionFilter: ITransactionFilter = {
    amount: 0,
    from: new Date(new Date().setDate(new Date().getDate() - 365))
      .toISOString()
      .split("T")[0],
    receiver: "",
    sender: "",
    to: new Date().toISOString().split("T")[0],
  };
  const [transactionFilter, SetTransactionFilter] =
    useState(_transactionFilter);

  const filterTransactions = async () => {
    console.log(JSON.stringify(transactionFilter));
    var response = await services.Post(
      END_POINTS_PATH.FilterTransactions,
      transactionFilter
    );

    if (response?.statusCode === 200) {
      setTransactions(response.data);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    var response = await services.GetList(END_POINTS_PATH.GetTransactions);
    if (response?.statusCode === 200) {
      setTransactions(response.data);
    }
  };

  const sendAgain = async (transaction: ITransaction) => {
    const topupRequest = { TransactionId: transaction.ttno };

    var response = await services.Post(END_POINTS_PATH.TopAgain, topupRequest);

    if (response?.statusCode === 200) {
      ShowSnackbar("Transaction sent successfully", "success");
      return;
    }

    ShowSnackbar("Transaction failed", "error");
  };

  return (
    <ReportTable
      transactions={transactions}
      sendAgain={sendAgain}
      filterTransactions={filterTransactions}
      transactionFilter={transactionFilter}
      SetTransactionFilter={SetTransactionFilter}
      headerText="Transaction Report"
    />
  );
}
