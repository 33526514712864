import { Link } from "react-router-dom";

export default function Forbidden() {
  return (
    <div className="hor-content main-content mt-0 error-bg d-flex flex-column justify-content-center" style={{ zIndex: 400 }}>
      <div className="page-content error-page error2 error-bg">
        <div className="container text-center">
          <div className="error-template">
            <h2 className="text-white mb-2">
              403<span className="fs-20">forbidden</span>
            </h2>
            <h5 className="error-details text-white">
              You don't have permission to access this page. Please contact the
              administrator for more information.
            </h5>
            <div className="text-center">
              <Link className="btn btn-primary mt-5 mb-5" to="/">
                <i className="fa fa-long-arrow-left"></i> Back to Home{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
