import { ReactNode, useEffect } from "react";
import Header from "./common/header";
type Props = {
  children: ReactNode;
};

function MainLayout({ children }: Props) {

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        document.getElementById('back-to-top')?.classList.add('fade-in');
        document.getElementById('back-to-top')?.classList.remove('fade-out');
      } else {
        document.getElementById('back-to-top')?.classList.add('fade-out');
        document.getElementById('back-to-top')?.classList.remove('fade-in');
      }

      if (window.scrollY >= 10) {
        document.querySelector('.horizontal-main')?.classList.add('fixed-header', 'visible-title');
      } else {
        document.querySelector('.horizontal-main')?.classList.remove('fixed-header', 'visible-title');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="page-main">
      {/* <div className="jumps-prevent"  style={{paddingTop: "74px"}}></div> */}
      <Header/>
      {children}
    </div>
  );
}

export default MainLayout;
