import React, { useContext, useState } from "react";
import { EntityServices } from "../Services/EntityServices";
import { ICredentials, ILogIn } from "../types/types";
import { setSession } from "../auth/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/authContext";
import ShowSnackbar from "../Services/snackbar";
import { END_POINTS_PATH } from "../routes/paths";

type LogInResponse = {
  token: string;
};

function LogInPage() {

  const navigate = useNavigate();

    const services = new EntityServices(navigate);

  const [credentials, SetCredentials] = useState({} as ILogIn);

  const { setAuthenticated } = useContext(AuthContext);

  const [isloading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    SetCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    setIsLoading(true);

    if (credentials.Username === "" || credentials.Username === undefined) {
      ShowSnackbar("Username is required", "danger");
      setIsLoading(false);
      return;
    }

    if (credentials.Password === "" || credentials.Password === undefined) {
      ShowSnackbar("password is required", "danger");
      setIsLoading(false);
      return;
    }

    if (credentials.Token === "" || credentials.Token === undefined) {
      ShowSnackbar("Token is required", "danger");
      setIsLoading(false);
      return;
    }

    try {
      var response = await services.Post(END_POINTS_PATH.GetToken, credentials);

      if (response?.statusCode === 200) {
        setSession(response.data);
        setAuthenticated(true);
        ShowSnackbar("Welcome", "primary");
      } else {
        ShowSnackbar("Invalid Credentials", "danger");
      }
    } catch (error) {
      ShowSnackbar("Failed", "danger");
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="col col-login mx-auto text-center">
        <a href="index.html" className="text-center">
          <img
            src="./assets/images/DahabGreen.png"
            className="header-brand-img"
            alt=""
          />
        </a>
      </div>
      <div className="container-login100">
        <div className="wrap-login100 p-0">
          <div className="card-body justify-content-center">
            <span
              className="login100-form-title"
              style={{ paddingBottom: "20px" }}
            >
              Login
            </span>
            <div className="text-center mb-6">
              <span>Use your username and password to log In</span>
            </div>
            <div
              asp-validation-summary="ModelOnly"
              className="text-danger small "
            ></div>
            {/* USERNAME */}

            <div
              className="wrap-input100 validate-input"
              data-bs-validate="Valid email is required: ex@abc.xyz"
            >
              <input
                autoComplete="off"
                className="input100"
                type="text"
                placeholder="Username"
                name="Username"
                value={credentials.Username}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="zmdi zmdi-email" aria-hidden="true"></i>
              </span>
            </div>
            {/* PASSWORD */}
            <div
              className="wrap-input100 validate-input"
              data-bs-validate="Password is required"
            >
              <input
                autoComplete="off"
                className="input100"
                type="password"
                placeholder="Password"
                name="Password"
                value={credentials.Password}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="zmdi zmdi-lock" aria-hidden="true"></i>
              </span>
            </div>

            {/* TOKEN */}
            <div
              className="wrap-input100 validate-input"
              data-bs-validate="Token is required"
            >
              <input
                autoComplete="off"
                className="input100"
                type="password"
                placeholder="Token"
                name="Token"
                value={credentials.Token}
                onChange={handleChange}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="zmdi zmdi-lock" aria-hidden="true"></i>
              </span>
            </div>

            <div className="container-login100-form-btn">
              <button
                id="registerSubmit"
                className={
                  isloading
                    ? "login100-form-btn btn-primary-light"
                    : "login100-form-btn btn-primary"
                }
                onClick={onSubmit}
              >
                Login &nbsp; {isloading && <i className="fa fa-spinner fa-spin"></i>}
              </button>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col text-center">
                <p>
                  <br /> <br /> © 2024 Dahabshiil - All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogInPage;
