import { useContext, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { setSession } from "../auth/utils";
import { AuthContext } from "../auth/authContext";
import { APP_PAGES } from "../routes/paths";
import { url } from "inspector";
import { elements } from "../routes/elements";

export default function Header() {
  const { username, role, setAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // This will ensure the script is loaded when the component mounts
    const script = document.createElement("script");
    script.src = "./assets/js/landing.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onNewTopupFocusOrHover = () => {
    (document.getElementById("newTopup") as HTMLElement).classList.add(
      "text-white"
    );
  };

  const LogOut = () => {
    setSession(null);
    setAuthenticated(false);
    return <Navigate to="./login" replace={true} />;
  };

  return (
    <div className="app-header sticky">
      <div className="container-fluid main-container">
        <div className="d-flex">
          <a
            aria-label="Hide Sidebar"
            className="app-sidebar__toggle"
            data-bs-toggle="sidebar"
            href="#"
          ></a>
          {/* <!-- sidebar-toggle--> */}
          <Link className="logo-horizontal " to="/">
            <img
              style={{
                height: "3.3rem",
                lineHeight: "3rem",
                verticalAlign: "top",
                width: "13.5rem",
              }}
              src="./assets/images/dahabshiil.jpg"
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              style={{
                height: "3.3rem",
                lineHeight: "3rem",
                verticalAlign: "top",
                width: "13.5rem",
              }}
              src="./assets/images/dahabshiil.jpg"
              className="header-brand-img light-logo1"
              alt="logo"
            />
          </Link>
          {/* <!-- LOGO --> */}

          <div className="d-flex order-lg-2 ms-auto header-right-icons">
            <div className="dropdown d-xl-none d-md-block d-none">
              <a href="#" className="nav-link icon" data-bs-toggle="dropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill=""
                    d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z"
                  />
                </svg>
              </a>
            </div>
            <button
              className="navbar-toggler navresponsive-toggler d-md-none ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent-4"
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fe fe-more-vertical"></span>
            </button>
            <div className="navbar navbar-collapse responsive-navbar p-0">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2">
                  {/* // main header menu */}

                  <div className="main-sidemenu navbar px-0">
                    <ul className="side-menu">
                      {elements.map((element) => {
                        if (!element.HideInHeaderNavigation) {
                          if (
                            element.Previlage &&
                            element.Previlage.includes(role ?? "")
                          ) {
                            {
                              return (
                                <li className="slide">
                                  <Link
                                    className="side-menu__item"
                                    data-bs-toggle="slide"
                                    to={element.Path}
                                  >
                                    <span className="side-menu__label">
                                      {element.Name}
                                    </span>
                                  </Link>
                                </li>
                              );
                            }
                          }
                        }
                      })}
                      <li
                        className="slide bg-primary"
                        onMouseEnter={onNewTopupFocusOrHover}
                      >
                        <Link
                          className="side-menu__item"
                          data-bs-toggle="slide"
                          to={APP_PAGES.Topup}
                        >
                          <span
                            id="newTopup"
                            className=""
                            style={{
                              color: "#fff",
                              whiteSpace: "nowrap",
                              WebkitBoxFlex: 1,
                              msFlex: "1 1 auto",
                              flex: "1 1 auto",
                              position: "relative",
                              top: "1px",
                              lineHeight: "inherit",
                            }}
                          >
                            New Topup
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* // end main header menu */}
                  <div className="dropdown d-md-flex profile-1">
                    <a
                      href="#"
                      data-bs-toggle="dropdown"
                      className="nav-link pe-2 leading-none d-flex animate"
                    >
                      <span>
                        <img
                          src="../assets/images/faces/6.jpg"
                          alt="profile-user"
                          className="avatar  profile-user brround cover-image"
                        />
                      </span>
                      <div className="text-center p-1 d-flex d-lg-none-max">
                        <h6 className="mb-0" id="profile-heading">
                          <span className="fs-14">{username}</span>
                          <i className="user-angle ms-1 fa fa-angle-down "></i>
                        </h6>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <button className="dropdown-item" onClick={LogOut}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-inner-icn"
                          enable-background="new 0 0 24 24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M10.6523438,16.140625c-0.09375,0.09375-0.1464233,0.2208862-0.1464233,0.3534546c0,0.276123,0.2238159,0.5,0.499939,0.500061c0.1326294,0.0001221,0.2598267-0.0525513,0.3534546-0.1464844l4.4941406-4.4941406c0.000061-0.000061,0.0001221-0.000061,0.0001831-0.0001221c0.1951294-0.1952515,0.1950684-0.5117188-0.0001831-0.7068481L11.359314,7.1524048c-0.1937256-0.1871338-0.5009155-0.1871338-0.6947021,0c-0.1986084,0.1918335-0.2041016,0.5083618-0.0122681,0.7069702L14.2930298,11.5H2.5C2.223877,11.5,2,11.723877,2,12s0.223877,0.5,0.5,0.5h11.7930298L10.6523438,16.140625z M16.4199829,3.0454102C11.4741821,0.5905762,5.4748535,2.6099243,3.0200195,7.5556641C2.8970337,7.8029175,2.9978027,8.1030884,3.2450562,8.2260742C3.4923706,8.3490601,3.7925415,8.248291,3.9155273,8.0010376c0.8737793-1.7612305,2.300354-3.1878052,4.0615845-4.0615845C12.428833,1.730835,17.828064,3.5492554,20.0366821,8.0010376c2.2085571,4.4517212,0.3901367,9.8509521-4.0615845,12.0595703c-4.4517212,2.2085571-9.8510132,0.3901367-12.0595703-4.0615845c-0.1229858-0.2473145-0.4231567-0.3480835-0.6704102-0.2250977c-0.2473145,0.1229858-0.3480835,0.4230957-0.2250977,0.6704102c1.6773682,3.4109497,5.1530762,5.5667114,8.9541016,5.5537109c3.7976685,0.0003662,7.2676392-2.1509399,8.9560547-5.5526733C23.3850098,11.4996338,21.3657227,5.5002441,16.4199829,3.0454102z" />
                        </svg>
                        Log out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
