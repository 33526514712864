// generate modal for top up using shortcut
// This is the modal for top up using shortcut in the dashboard will collect the Sender and Recipient information same like #topup.tsx

import { useEffect, useState } from "react";
import { ICustomer, IShorcutTransaction } from "../../types/types";
import { APP_PAGES, END_POINTS_PATH } from "../../routes/paths";
import { EntityServices } from "../../Services/EntityServices";
import ShowSnackbar from "../../Services/snackbar";
import ExistedCustomerModel from "../topUp/ExistedCustomerModel";
import { useNavigate } from "react-router-dom";

interface Props {
  shortcutId: string;
}

export default function TopUpUsingShortcut({ shortcutId }: Props) {
  
  const navigate = useNavigate();
    const services = new EntityServices(navigate);

  const [transaction, setTransaction] = useState({} as IShorcutTransaction);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(
    null as boolean | null
  );
  const [isValidPhoneNumberLoading, setIsValidPhoneNumberLoading] =
    useState(false);
  const [topupButtonDisabled, setTopupButtonDisabled] = useState(true);

  useEffect(() => {
    transaction.shortcutId = shortcutId;

    const modalElement = document.getElementById("topUpUsingShortcut");
    const headerElement = document.querySelector(".page-header");
    const bodyElement = document.body;

    const handleShow = () => {
      if (headerElement) {
        headerElement.classList.add("no-transparency");
      }
      if (bodyElement) {
        bodyElement.classList.add("no-scroll");
      }
    };

    const handleHide = () => {
      if (headerElement) {
        headerElement.classList.remove("no-transparency");
      }
      if (bodyElement) {
        bodyElement.classList.remove("no-scroll");
      }
    };

    if (modalElement) {
      modalElement.addEventListener("show.bs.modal", handleShow);
      modalElement.addEventListener("hide.bs.modal", handleHide);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("show.bs.modal", handleShow);
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      }
    };
  }, []);

  const handleOnBlurRecipeintNumber = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      var isValid = await checkRecipientPhoneNumber(e.target.value);

      if (isValid === false) {
        setIsValidPhoneNumber(false);
        setTopupButtonDisabled(true);
        return;
      }
      setIsValidPhoneNumber(true);
      setTopupButtonDisabled(false);
    }
  };

  const checkRecipientPhoneNumber = async (phone: String): Promise<Boolean> => {
    try {
      setIsValidPhoneNumberLoading(true);
      var response = await services.GetDataByfilterAsync(
        END_POINTS_PATH.CheckRecipientPhoneNumber + "/" + phone
      );
      console.log("Response", response);

      if (response === null) {
        return false;
      }

      var result = JSON.parse(response.data);

      setIsValidPhoneNumberLoading(false);
      return result.data.IsValid;
    } catch (error) {
      console.log("Failed to check phone number");
      return false;
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTransaction((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const checkIfFormIsValid = (): boolean => {
    return (
      transaction.senderName !== "" &&
      transaction.senderPhone !== "" &&
      transaction.recipientName !== "" &&
      transaction.recipientPhone !== "" &&
      isValidPhoneNumber === true
    );
  };

  const setCustomer = (customer: ICustomer) => {
    setTransaction((prev) => ({
      ...prev,
      senderName: customer.fullname,
      senderPhone: customer.phone,
    }));
  };

  const onSubmit = async () => {
    console.log("Transaction", transaction);
    try {
      var response = await services.Post(
        END_POINTS_PATH.TopupShortcut,
        transaction
      );

      if (response === null) {
        ShowSnackbar("An error occured", "error");
        return;
      }

      if (response?.statusCode !== 200) {
        ShowSnackbar(response?.error ?? "Failed", "error");
        return;
      }

      window.location.href = APP_PAGES.Transactions;
      ShowSnackbar("Transaction sent successfully", "success");
    } catch (error) {
      console.log("Failed to top up using shortcut");
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#topUpUsingShortcut"
      >
        <i className="fe fe-plus"></i>
        Top Up
      </button>

      <div
        className="modal fade custom-modal"
        id="topUpUsingShortcut"
        tabIndex={200}
        role="dialog"
        aria-labelledby="topUpUsingShortcutLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered  modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="topUpUsingShortcutLabel">
                Topup
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="row">
                {/* Add Sender Form */}
                <div className="col-sm-12 col-md-12 col-xl-7">
                  <div className="form-group">
                    <label htmlFor="sender" className="form-label text-muted">
                      Sender Name <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="sender"
                      placeholder="Sender Fullname"
                      name={"senderName"}
                      value={transaction.senderName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Phone */}
                <div className="col-sm-12 col-md-12 col-xl-5">
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label text-muted">
                      Sender Phone <span className="text-red">*</span>{" "}
                      {/* <ExistedCustomerModel
                        phone={transaction.senderPhone}
                        setCustomer={setCustomer}
                      /> */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder="Phone"
                      name={"senderPhone"}
                      value={transaction.senderPhone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Add Recipient Form */}
                <div className="col-sm-12 col-md-12 col-xl-6">
                  <div className="form-group">
                    <label
                      htmlFor="recipient"
                      className="form-label text-muted"
                    >
                      Recipient Fullname <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient"
                      placeholder="Recipient Name"
                      name={"recipientName"}
                      value={transaction.recipientName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label text-muted">
                      Recipient Phone Number :{" "}
                      {isValidPhoneNumberLoading ? (
                        <small>Validating...</small>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      style={
                        isValidPhoneNumber === null
                          ? {}
                          : isValidPhoneNumber
                          ? { border: "2px solid #28a745" }
                          : { border: "2px solid #dc3545" }
                      }
                      id="phone"
                      placeholder="Recipient Phone Number"
                      name={"recipientPhone"}
                      value={transaction.recipientPhone}
                      onBlur={handleOnBlurRecipeintNumber}
                      onChange={handleChange}
                    />
                    <small
                      style={
                        isValidPhoneNumber === null
                          ? { color: "#707070" }
                          : isValidPhoneNumber
                          ? { color: "#28a745" }
                          : { color: "#dc3545" }
                      }
                    >
                      {isValidPhoneNumber === null
                        ? "Please enter the phone number with the country code e.g 252XXXXXXXXX"
                        : isValidPhoneNumber
                        ? "Phone number is valid"
                        : "Phone number is invalid"}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <h6>
                Please fill in the sender and recipient information to proceed
              </h6>
              <div>
                <button
                  type="button"
                  className="btn btn-default me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!checkIfFormIsValid() || topupButtonDisabled}
                  onClick={onSubmit}
                >
                  Top Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
