import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      className="hor-content main-content error-bg d-flex flex-column justify-content-center"
      style={{ zIndex: 400 }}
    >
      <div className="page-content error-page error2">
        <div className="container text-center">
          <div className="error-template">
            <h2 className="text-white mb-2">
              404<span className="fs-20">error</span>
            </h2>
            <h5 className="error-details text-white">
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </h5>
            <div className="text-center">
              <Link className="btn btn-primary mt-5 mb-5" to="/">
                {" "}
                <i className="fa fa-long-arrow-left"></i> Back to Home{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
