import { useContext } from "react";
import { Navigate, Outlet, Route, Routes as Router } from "react-router-dom";
import { AuthContext } from "../auth/authContext";
import Home from "../pages/dashboard/home";
import { isValidToken } from "../auth/utils";
import MainLayout from "../mainLayout";
import LogInPage from "../pages/LogInPage";
import { APP_PAGES } from "./paths";
import { elements } from "./elements";
import Forbidden from "../common/403";
import NotFound from "../common/404";
type Props = {};

const PrivateRoutes = () => {
  const { authenticated, setAuthenticated } = useContext(AuthContext);

  if (!isValidToken()) {
    setAuthenticated(false);
  } else {
    setAuthenticated(true);
  }

  if (!authenticated) return <Navigate to="./login" replace={true} />;

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const Routes = (props: Props) => {
  const { role } = useContext(AuthContext);
  return (
    <Router>
      <Route path="/login" element={<LogInPage />} />
      <Route element={<PrivateRoutes />}>
        {elements.map((element) => {
          if (element.Previlage?.includes(role ?? "")) {
            if (element.HasRoutingId) {
              return (
                <Route
                  key={element.Path}
                  path={element.Path + "/:id"}
                  element={element.Component}
                />
              );
            } else {
              return (
                <Route
                  key={element.Path}
                  path={element.Path}
                  element={element.Component}
                />
              );
            }
          } else {
            return (
              <Route
                key={element.Path}
                path={element.Path}
                element={<Forbidden />}
              />
            );
          }
        })}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Router>
  );
};

export default Routes;
