import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { AuthProvider } from "./auth/authContext";
import AuthGuard from "./auth/authGuard";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {

  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <AuthProvider>
        <AuthGuard>
          <QueryClientProvider client={queryClient}>
          <Routes />
          </QueryClientProvider>
        </AuthGuard>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
