import { ITransactionFilter } from "../../types/types";

interface Props {
  filterTransactions: () => void;
  transactionFilter: ITransactionFilter;
  SetTransactionFilter: (
    transactionFilter: React.SetStateAction<ITransactionFilter>
  ) => void;
  headerText: string;
}

export default function FilterTranscation({
  filterTransactions,
  SetTransactionFilter,
  transactionFilter,
  headerText,
}: Props) {
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    if (name === "amount") {
      if (value === "") {
        SetTransactionFilter((prev) => ({
          ...prev,
          [name]: 0,
        }));
        return;
      } else {
        SetTransactionFilter((prev) => ({
          ...prev,
          [name]: parseFloat(value),
        }));
      }
    } else {
      SetTransactionFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const reset = () => {
    document.querySelectorAll("input").forEach((input) => {
      input.value = "";
    });

    var fromDate = new Date(new Date().setDate(new Date().getDate() - 365))
      .toISOString()
      .split("T")[0];
    var toDate = new Date().toISOString().split("T")[0];
    SetTransactionFilter({
      amount: 0,
      from: fromDate,
      receiver: "",
      sender: "",
      to: toDate,
    });
  };

  return (
    <div className="card bg-white shadow-sm">
      <div className="card-body">
        <h5 className="card-title">Filter {headerText} </h5>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-3">
              <label className="form-label">From</label>
              <input
                type="date"
                name="from"
                defaultValue={transactionFilter.from}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label className="form-label">To</label>
              <input
                type="date"
                name="to"
                defaultValue={transactionFilter.to}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label className="form-label">Amount</label>
              <input
                type="number"
                name="amount"
                defaultValue={transactionFilter.amount}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">Sender</label>
              <input
                type="text"
                name="sender"
                defaultValue={transactionFilter.sender}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">Receiver</label>
              <input
                type="text"
                name="receiver"
                defaultValue={transactionFilter.receiver}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="text-end pt-3 ">
            <button onClick={reset} className="btn btn-default">
              Clear
            </button>{" "}
            &nbsp;
            <button onClick={filterTransactions} className="btn btn-primary">
              <i className="fa fa-search"></i> &nbsp; Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
