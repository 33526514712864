import { useEffect, useState } from "react";
import { IAgent } from "../../types/types";
import { EntityServices } from "../../Services/EntityServices";
import { END_POINTS_PATH } from "../../routes/paths";
import ShowSnackbar from "../../Services/snackbar";
import AgentForm from "./AgentForm";
import { useNavigate } from "react-router-dom";

export default function Agents() {
  const navigate = useNavigate();
    const services = new EntityServices(navigate);

  const [agents, setAgents] = useState([] as IAgent[]);

  const [originalAgents, setOriginalAgents] = useState([] as IAgent[]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = async () => {
    var response = await services.GetList(END_POINTS_PATH.Agents);

    if (response === null) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    const data = response.data as IAgent[];

    setAgents(data);
    setOriginalAgents(data);
    setLoading(false);
  };

  const changeRole = async (agent: IAgent) => {
    var response = await services.Post(END_POINTS_PATH.ChangeRole, agent);

    if (response === null) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    getAgents();
  };

  const deactivate = async (agent: IAgent) => {
    var response = await services.Put(END_POINTS_PATH.Deactivate, agent);

    if (response === null) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    if (response?.statusCode !== 200) {
      ShowSnackbar("An error occured", "error");
      return;
    }

    getAgents();
  };

  const searchAgent = (e: any) => {
    if (e.target.value === "") {
      setAgents(originalAgents);
      return;
    }

    const value = e.target.value.toLowerCase();

    const filteredAgent = originalAgents.filter((agent) => {
      return (
        (agent?.username && agent?.username?.toLowerCase().includes(value)) ||
        agent.id.includes(value)
      );
    });

    setAgents(filteredAgent);
  };

  return (
    <>
      <div className="hor-content main-content mt-0">
        <div className="side-app">
          <div className="main-container container">
            {/* Transactions Table */}

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body px-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="m-0">All Agents</h4>
                    </div>

                    <div className="row clients-contacts-main mt-3">
                      <div className="col-md-12 col-xl-12">
                        <div className="card bg-white shadow-sm">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-xl col-lg-6 col-md-12">
                                <div className="btn-list">
                                  <AgentForm
                                    id={"add"}
                                    title="Add Agent"
                                    isEdit={false}
                                    refreshAgents={getAgents}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-6 col-md-12 mt-1 mt-lg-0">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search in clients....."
                                    onChange={searchAgent}
                                  />
                                  <button
                                    className="btn ripple btn-primary text-white input-group-text border-0"
                                    type="button"
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 ps-2">
                        {loading === false ? (
                          <table
                            id="project-table"
                            className="table text-nowrap mb-0 table-bordered border-top border-bottom project-list-main"
                          >
                            <thead className="table-head">
                              <tr>
                                <th className="border-bottom-0">No</th>
                                <th className="border-bottom-0">Agent Name</th>
                                <th className="border-bottom-0">Role</th>
                                <th className="border-bottom-0">Status</th>
                                <th className="border-bottom-0">Action</th>
                              </tr>
                            </thead>
                            <tbody className="table-body">
                              {agents.sort((a, b) => a.username.localeCompare(b.username)).map((agent) => (
                                <tr>
                                  <td>
                                    {(agents.indexOf(agent) + 1)
                                      .toString()
                                      .padStart(2, "0")}
                                  </td>
                                  <td>{agent?.username}</td>
                                  <td>{agent?.role}</td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-xs ms-2 " +
                                        (agent.isGranted === true
                                          ? "bg-primary"
                                          : "bg-gray")
                                      }
                                    >
                                      {agent.isGranted === true
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </td>
                                  <td className="d-flex flex-row">
                                    <button
                                      onClick={() => changeRole(agent)}
                                      className="btn btn-outline-primary btn-sm d-none d-sm-block me-2"
                                    >
                                      {agent.role !== "Admin"
                                        ? "Make Admin"
                                        : "Make Agent"}
                                    </button>
                                    <AgentForm
                                      id={agent?.id}
                                      title="Edit Agent"
                                      isEdit={true}
                                      existedAgent={agent}
                                      refreshAgents={getAgents}
                                    />
                                    <button
                                      className={
                                        "btn btn-sm hpx-35 d-flex align-items-center justify-content-center " +
                                        (agent?.isGranted === true
                                          ? "btn-danger"
                                          : "btn-success")
                                      }
                                      onClick={() => deactivate(agent)}
                                    >
                                      {agent?.isGranted === true
                                        ? "Deactivate"
                                        : "Activate"}
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            <div className="dimmer active">
                              <div className="spinner1">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
