import { ReactNode, useState } from "react"
import { createContext } from "react"
import { useNavigate } from "react-router-dom"
import { getRole, getUsername } from "./utils"

type Props = {
    children?: ReactNode
}

interface IAuthContext {
    authenticated : boolean,
    username : string | null,
    role : string | null,
    setAuthenticated : (newState : boolean) => void
}
const initailValue : IAuthContext = {
    authenticated : false,
    username : getUsername(),
    role : getRole(),
    setAuthenticated : () => {}
}
const AuthContext = createContext<IAuthContext>(initailValue);

const AuthProvider = ({children}: Props) => {

    const [authenticated, setAuthenticated] = useState(initailValue.authenticated);

    const username = getUsername();

    const role = getRole();

    const navigate = useNavigate()

    return (
        <AuthContext.Provider value={{authenticated, username, role, setAuthenticated}}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}