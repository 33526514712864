import Agents from "../pages/agent";
import Home from "../pages/dashboard/home";
import AgentReport from "../pages/Reports/agentReport";
import CommissionReport from "../pages/Reports/commissionReport";
import CountriesReport from "../pages/Reports/countriesReport";
import MainReportPage from "../pages/Reports/main";
import TransactionReport from "../pages/Reports/transactionReport";
import Topup from "../pages/topUp/topUp";
import Reciept from "../pages/transactionDetails/reciept";
import TransactionDetails from "../pages/transactionDetails/transaction_Details";
import TransactionsList from "../pages/transactions/transactionsList";
import { APP_PAGES } from "./paths";
import { Element } from "./types";

export const elements: Element[] = [
  {
    Name: "Home",
    Path: APP_PAGES.Home,
    Component: <Home />,
    Previlage: ["Admin", "Agent"],
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="side-menu__icon"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
      >
        <path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" />
      </svg>
    ),
  },
  {
    Name: "Top Up",
    Path: APP_PAGES.Topup,
    HideInHeaderNavigation: true,
    Component: <Topup />,
    Previlage: ["Admin", "Agent"],
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="side-menu__icon"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
      >
        <path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" />
      </svg>
    ),
  },
  {
    Name: "Transactions",
    Path: APP_PAGES.Transactions,
    Component: <TransactionsList />,
    Previlage: ["Admin", "Agent"],
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
        className="side-menu__icon"
      >
        <title>credit-card</title>
        <path d="M5,5H18C19.66,5 21,6.34 21,8V17C21,18.66 19.66,20 18,20H5C3.34,20 2,18.66 2,17V8C2,6.34 3.34,5 5,5M5,6C3.9,6 3,6.9 3,8V9H20V8C20,6.9 19.1,6 18,6H5M3,17C3,18.1 3.9,19 5,19H18C19.1,19 20,18.1 20,17V12H3V17M5,16H9V17H5V16M11,16H14V17H11V16M3,10V11H20V10H3Z" />
      </svg>
    ),
  },
  {
    Name: "Transaction Cards",
    Path: APP_PAGES.TransactionsList,
    HideInHeaderNavigation: true,
    Component: <TransactionsList />,
    Previlage: ["Admin", "Agent"],
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="side-menu__icon"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
      >
        <path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" />
      </svg>
    ),
  },
  {
    Name: "Transactions Details",
    Path: APP_PAGES.TransactionDetials,
    HideInHeaderNavigation: true,
    HasRoutingId: true,
    Component: <TransactionDetails />,
    Previlage: ["Admin", "Agent"],
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="side-menu__icon"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
      >
        <path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" />
      </svg>
    ),
  },
  {
    Name: "Reciept",
    Path: APP_PAGES.Reciept,
    HideInHeaderNavigation: true,
    HasRoutingId: true,
    Component: <Reciept />,
    Previlage: ["Admin", "Agent"],
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="side-menu__icon"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
      >
        <path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" />
      </svg>
    ),
  },
  {
    Name: "Agents",
    Path: APP_PAGES.Agents,
    HideInHeaderNavigation: false,
    HasRoutingId: false,
    Component: <Agents/>,
    Previlage: ["Admin"],    
  },
  // Reports
  {
    Name: "Reports",
    Path: APP_PAGES.MainReport,
    HideInHeaderNavigation: false,
    HasRoutingId: false,
    Component: <MainReportPage />,
    Previlage: ["Admin", "Agent"],
  },
  {
    Name: "Agent Report",
    Path: APP_PAGES.AgentReport,
    HideInHeaderNavigation: true,
    Component: <AgentReport />,
    Previlage: ["Admin", "Agent"],   
  },
  {
    Name: "Transaction Report",
    Path: APP_PAGES.TransactionReport,
    HideInHeaderNavigation: true,
    Component: <TransactionReport />,
    Previlage: ["Admin"],    
  },
  {
    Name: "Customer Report",
    Path: APP_PAGES.CustomerReport,
    HideInHeaderNavigation: true,
    Component: <TransactionReport />,
    Previlage: ["Admin"]
  },  
  {
    Name: "Country Report",
    Path: APP_PAGES.CountryReport,
    HideInHeaderNavigation: true,
    Component: <CountriesReport />,
    Previlage: ["Admin"]
  },
  {
    Name: "Operator Report",
    Path: APP_PAGES.OperatorReport,
    HideInHeaderNavigation: true,
    Component: <TransactionReport />,
    Previlage: ["Admin"]
  },
  {
    Name: "Commission Report",
    Path: APP_PAGES.CommissionReport,
    HideInHeaderNavigation: true,
    Component: <CommissionReport />,
    Previlage: ["Admin"]
  },
  {
    Name: "Payment Report",
    Path: APP_PAGES.PaymentReport,
    HideInHeaderNavigation: true,
    Component: <TransactionReport />,
    Previlage: ["Admin"]
  }
  
];
