

// ----------------------------------------------------------------------

import axios from "../utils/axios";
import { PATH_AUTH } from "../routes/paths";
import { NavigateFunction } from "react-router-dom";
import { Console } from "console";

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  
  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (): boolean => {
  
  const token = localStorage.getItem('accessToken');

  if(token == null) return false;

  const decoded = jwtDecode(token);

  const currentTime = Date.now() / 1000;

  if(decoded.exp > currentTime)
  {
     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const getUsername = (): string | null => {
  
  const token = localStorage.getItem('accessToken');

  if(token == null) return null;

  const decoded = jwtDecode(token);

  return decoded.username;
};

export const getRole = (): string | null => {
    
    const token = localStorage.getItem('accessToken');
  
    if(token == null) return null;
  
    const decoded = jwtDecode(token);
  
    return decoded.role;
  };
  
// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server

    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const getSession = () : string | null => { 
   const token = localStorage.getItem('accessToken');
   return token;
};


export function handleUnauthorized(navigate: NavigateFunction) {
  console.log('Unauthorized' + navigate);
  localStorage.removeItem('accessToken');
  navigate('/login');
}