import { IShortcuts } from "../../types/types";
import TopUpUsingShortcut from "./TopupShortcut";

interface Props {
  shortcuts: IShortcuts[];
}

export default function Shortcuts({ shortcuts: shorcuts }: Props) {

  return (
    <div className="row">
      <div className="col-xs-12 mb-3">
        <h4 className="card-title fw-semibold">Shortcuts</h4>
      </div>
      {shorcuts.length === 0 ? (
        <div className="list-group-item border-0 ms-2 mr-5">
          <div className="media mt-0 align-items-center">
            <div className="transaction-icon">
              <i className="fe fe-chevrons-right"></i>
            </div>
            <div className="media-body">
              <p>No shortcuts were found.</p>
            </div>
          </div>
        </div>
      ) : (
        shorcuts.map((shortcut, index) => (
          <div className="col-sm-12 col-md-5">
            <div className="card bg-white overflow-hidden">
              <div className="card-body">
                <div className="row pb-2">
                  <div className="col">
                    <h3 className="mb-2 fw-semibold">${shortcut.amount}</h3>
                    <p className="text-muted fs-13 mb-0">
                      <span className="text-primary pe-3 border-end-1">
                        {shortcut.country}
                      </span>
                      <span className="ms-2">
                        {shortcut.operatorName}
                      </span>
                    </p>
                  </div>
                  <div className="col col-auto top-icn dash">
                    <div className="counter-icon bg-primary dash ms-auto box-shadow-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="fill-white"
                        enable-background="new 0 0 24 24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9,10h2.5c0.276123,0,0.5-0.223877,0.5-0.5S11.776123,9,11.5,9H10V8c0-0.276123-0.223877-0.5-0.5-0.5S9,7.723877,9,8v1c-1.1045532,0-2,0.8954468-2,2s0.8954468,2,2,2h1c0.5523071,0,1,0.4476929,1,1s-0.4476929,1-1,1H7.5C7.223877,15,7,15.223877,7,15.5S7.223877,16,7.5,16H9v1.0005493C9.0001831,17.2765503,9.223999,17.5001831,9.5,17.5h0.0006104C9.7765503,17.4998169,10.0001831,17.276001,10,17v-1c1.1045532,0,2-0.8954468,2-2s-0.8954468-2-2-2H9c-0.5523071,0-1-0.4476929-1-1S8.4476929,10,9,10z M21.5,12H17V2.5c0.000061-0.0875244-0.0228882-0.1735229-0.0665283-0.2493896c-0.1375732-0.2393188-0.4431152-0.3217773-0.6824951-0.1842041l-3.2460327,1.8603516L9.7481079,2.0654297c-0.1536865-0.0878906-0.3424072-0.0878906-0.4960938,0l-3.256897,1.8613281L2.7490234,2.0664062C2.6731567,2.0227661,2.5871582,1.9998779,2.4996338,1.9998779C2.2235718,2.000061,1.9998779,2.223938,2,2.5v17c0.0012817,1.380188,1.119812,2.4987183,2.5,2.5H19c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-6.5006104C21.9998169,12.2234497,21.776001,11.9998169,21.5,12z M4.5,21c-0.828064-0.0009155-1.4990845-0.671936-1.5-1.5V3.3623047l2.7412109,1.5712891c0.1575928,0.0872192,0.348877,0.0875854,0.5068359,0.0009766L9.5,3.0761719l3.2519531,1.8583984c0.157959,0.0866089,0.3492432,0.0862427,0.5068359-0.0009766L16,3.3623047V19c0.0008545,0.7719116,0.3010864,1.4684448,0.7803345,2H4.5z M21,19c0,1.1045532-0.8954468,2-2,2s-2-0.8954468-2-2v-6h4V19z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="row pb-4 border-bottom">
                  <div className="col">
                    <span className="text-danger">
                      {shortcut.productCategory}
                    </span>
                  </div>
                  <div className="col text-end">
                    <span className="text-success">{shortcut.productName}</span>
                  </div>
                </div>

                <div className="row pt-4">
                  <div className="col text-end">
                   <TopUpUsingShortcut shortcutId={shortcut.id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
