import { Link } from "react-router-dom";
import { ITransaction } from "../../types/types";

interface TopUpAgainProps {
  transaction: ITransaction;
  submit: () => void;
}

function TopUpAgain({ transaction, submit }: TopUpAgainProps) {
  return (
    <>
      <button
        type="button"
        className="btn btn-outline-primary me-2"
        data-bs-toggle="modal"
        data-bs-target="#topupAgain"
      >
        Top Up Again
      </button>

      {/* <div className="modal fade" id="topupAgain" tabIndex={-1}
        role="dialog"
        aria-labelledby="topupAgainLabel"
        aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered text-center " role="document">
				<div className="modal-content tx-size-sm">
					<div className="modal-body text-center p-4">
						<button aria-label="Close" className="btn-close" data-bs-dismiss="modal" ><span aria-hidden="true">&times;</span></button>
						<i className="fe fe-check-circle fs-65 text-success lh-1 mb-5 d-inline-block"></i>
						<h4 className="text-success tx-semibold">Congratulations!</h4>
						<p className="text-muted">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p><button aria-label="Close" className="btn btn-success pd-x-25" data-bs-dismiss="modal" >Continue</button>
					</div>
				</div>
			</div>
		</div> */}
      <div
        className="modal fade"
        id="topupAgain"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="topupAgainLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content tx-size-sm">
            <div className="modal-body p-4">
              <div className="text-center">
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style={{ color: "#FFC107" }}
                  className="feather feather-alert-circle "
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="12"></line>
                  <line x1="12" y1="16" x2="12.01" y2="16"></line>
                </svg>
                <h4 className="text-primary tx-semibold mt-4 mb-5">
                  Topup Again
                </h4>
                <h5>Are you sure you want to topup again?</h5>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button data-bs-dismiss="modal" onClick={submit} className="btn btn-primary">
                Yes, try again
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopUpAgain;
