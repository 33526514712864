import { useState } from "react";
import { ITransaction } from "../../types/types";

interface Props {
  transaction: ITransaction;
  isSubmiting: boolean;
  submitTransaction: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TopConfirmModel({
  transaction,
  isSubmiting,
  submitTransaction,
  handleChange,
}: Props) {
  const [erorrMessage, setErrorMessage] = useState("");

  const verify = () => {
    if (transaction.tokenID === "" || transaction.tokenID === undefined) {
      setErrorMessage("Please enter your token");
      console.log("Please enter your token");
    } else {
      submitTransaction();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    handleChange(e);
  };

  return (
    <>
      <button
        className="btn btn-primary me-2 btn-sm"
        data-bs-toggle="modal"
        data-bs-target={"#submitTransaction"}
      >
        Submit
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-inner-icn text-white"
          enable-background="new 0 0 24 24"
          viewBox="0 0 24 24"
        >
          <title>chevron-double-right</title>
          <path d="M6.59,6.84L12.25,12.5L6.59,18.16L5.89,17.45L10.84,12.5L5.89,7.55L6.59,6.84M10.59,6.84L16.25,12.5L10.59,18.16L9.89,17.45L14.84,12.5L9.89,7.55L10.59,6.84Z" />
        </svg>
      </button>
      <div
        className="modal fade"
        id={"submitTransaction"}
        tabIndex={-1}
        aria-labelledby="editClient"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Verify you token before you submit
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group text-start">
                    <label htmlFor="username" className="form-label">
                      Token
                      <p className="text-danger">{erorrMessage}</p>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tokenID"
                      placeholder="Please enter your token here"
                      name="tokenID"
                      value={transaction?.tokenID}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default me-2"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={verify}
                type="button"
                disabled={isSubmiting}
                className={
                  !isSubmiting ? "btn btn-primary" : "btn btn-primary-light"
                }
              >
                {isSubmiting ? (
                  <p>
                    Submitting &nbsp;
                    <i className="fa fa-spinner fa-spin"></i>
                  </p>
                ) : (
                  "Validate & Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
