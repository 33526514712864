import { NavigateFunction } from 'react-router-dom';
import { handleUnauthorized } from '../auth/utils';
import { IResponse } from '../types/types';
import axios from '../utils/axios';

export default class APIGateway<T> {
  private navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  private async handleRequest(request: Promise<any>): Promise<IResponse | null> {
    let result: IResponse | null = null;
    try {
      var response = await request;

      result = {
        statusCode: response.status,
        isSuccessful: response.status >= 200 && response.status < 300,
        error: response.data.error,
        data: response.data.data,
        transactionId: response.data.transactionId
      };

      if (result?.statusCode === 401) {
        handleUnauthorized(this.navigate);
      }
      return result;
    } catch (exp) {
      return exp as IResponse;
    }
  }

  public async GetList(baseUrl: string): Promise<IResponse | null> {
    return this.handleRequest(axios.get(baseUrl));
  }

  public async GetSingle(baseUrl: string): Promise<IResponse | null> {
    return this.handleRequest(axios.get(baseUrl));
  }

  public async GetById(baseUrl: string, id: number): Promise<IResponse | null> {
    return this.handleRequest(axios.get(`${baseUrl}/api${id}`));
  }

  public async Post(baseUrl: string, data: T): Promise<IResponse | null> {
    return await this.handleRequest(axios.post(baseUrl, data));
  }

  public async PostFromAnotherModel<A>(baseUrl: string, data: A): Promise<IResponse | null> {
    return this.handleRequest(axios.post(baseUrl, data));
  }

  public async PostAppJosn(baseUrl: string, data: T): Promise<IResponse | null> {
    return this.handleRequest(axios.post(baseUrl, data));
  }

  public async Put(baseUrl: string, data: T): Promise<IResponse | null> {
    return this.handleRequest(axios.put(baseUrl, data));
  }

  public async PutFromAnotherModel<A>(baseUrl: string, data: A): Promise<IResponse | null> {
    return this.handleRequest(axios.put(baseUrl, data));
  }

  public async Delete(baseUrl: string): Promise<IResponse | null> {
    return this.handleRequest(axios.delete(baseUrl));
  }
}
