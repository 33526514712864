import { ITransaction, ITransactionFilter } from "../../types/types";
import FilterTranscation from "../transactions/filterTranscation";
import TransactionTable from "../transactions/transactionTable";

interface Props {
  headerText: string;
  transactions: ITransaction[];
  sendAgain: (transaction: ITransaction) => void;
  filterTransactions: () => void;
  SetTransactionFilter: (
    transactionFilter: React.SetStateAction<ITransactionFilter>
  ) => void;
  transactionFilter: ITransactionFilter;
}
export default function ReportTable({
  headerText,
  transactions,
  transactionFilter,
  filterTransactions,
  SetTransactionFilter,
  sendAgain,
}: Props) {
  return (
    <div className="hor-content main-content mt-0">
      <div className="side-app">
        <div className="main-container container">
          {/* Transactions Table */}

          <div className="row">
            <div className="col-md-12 my-4">
              <h4 className="mb-4 ">{headerText}</h4>
            </div>
            <div className="col-md-12 mx-0 px-0">
              <FilterTranscation
                headerText="Report"
                filterTransactions={filterTransactions}
                SetTransactionFilter={SetTransactionFilter}
                transactionFilter={transactionFilter}
              />
            </div>

            {/* add cards */}
            <div className="col-md-12 mx-0 px-0">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">Total Top-ups</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-20">
                          {transactions.length}
                          <span className="fs-13 text-muted"> Top-ups</span>
                        </p>
                        <div className="counter-icon bg-success dash ms-auto box-shadow-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">Total Top-up Amounts</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-20">
                          $
                          {(
                            transactions.reduce(
                              (acc, curr) => acc + curr.amount,
                              0
                            ) ?? 0
                          ).toFixed(2)}
                          <span className="fs-13 text-muted"> Top-ups</span>
                        </p>
                        <div className="counter-icon bg-warning dash ms-auto box-shadow-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">Countries</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-20">
                          {[
                            ...new Set(
                              transactions.map(
                                (transaction) => transaction.country
                              )
                            ),
                          ].length ?? 0}
                          <span className="fs-13 text-muted"> Countries</span>
                        </p>
                        <div className="counter-icon bg-info dash ms-auto box-shadow-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 bg-white shadow-sm">
              <div className="card">
                <div className="card-body project-list-table-container">
                  <div className="table-responsive">
                    <TransactionTable
                      transactions={transactions}
                      sendAgain={sendAgain}
                      displayColumns={[
                        "S/N",                        
                        "Transaction ID",
                        "Recipient",
                        "Sender",
                        "Country",
                        "Operator",
                        "Product Category",
                        "Date",
                        "Amount",
                        "PaidAmount",
                      ]}
                      excludeActions={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* End Transactions Table */}
        </div>
      </div>
    </div>
  );
}
