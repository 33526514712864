import { HOST_API_URL } from '../config';
import { IResponse } from '../types/types';
import APIGateway from './APIGateway';

export class EntityServices<T> extends APIGateway<T> {

    private baseUrl: string = HOST_API_URL + "/api";

    // Get All response
    public async GetAllAsync(
        url?: string,
        page?: number,
        itemsPerPage?: number
    ): Promise<IResponse | null> {
        if (page && itemsPerPage) {
            if (url) {
                var response = await this.GetList(
                    this.baseUrl + `/api${url}?page=${page}&ItemsPerPage=${itemsPerPage}`
                );
                return response;
            } else {
                var response = await this.GetList(this.baseUrl + `?page=${page}&ItemsPerPage=${itemsPerPage}`);
                return response;
            }
        } else {
            var response = await this.GetList(this.baseUrl + url);
            return response;
        }
    }

    // Get Data by filter
    public async GetdataByFilterAsync(url: string): Promise<IResponse | null> {
        try {
            const response = await this.GetList(this.baseUrl + url);
            return response;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    public async GetDataByIdAsync(id: number, url: string): Promise<IResponse | null> {
        let response = await this.GetById(this.baseUrl + url, id);
        return response;
    }

    public async GetDataByfilterAsync(url: string): Promise<IResponse | null> {
        const response = await this.GetSingle(this.baseUrl + url);
        return response;
    }

    public async AddAsync<A>(url: string, data: T): Promise<IResponse | null> {
        try {
            var response = await this.Post(this.baseUrl + url, data);
            console.log("This is the response from entity services.", response);
            return null;
        } catch (error) {
            console.log("This is the error from entity services.", error);
            return null;
        }
    }

    public async AddFromAnotherModelAsync<A>(url: string, data: A): Promise<any> {
        const response = await this.PostFromAnotherModel<A>(this.baseUrl + url, data);
        return response;
    }

    public async UpdateAsync(url: string, data: T, id: number | string): Promise<any> {
        let response = await this.Put(`${this.baseUrl}${url}/${id}`, data)
        return response;
    }

    public async UpdateFromAnotherModelAsync<A>(
        response: A,
        id: number | string,
        additionUrl?: string
    ): Promise<any> {
        let result = additionUrl
            ? await this.PutFromAnotherModel<A>(`${this.baseUrl}${additionUrl}/${id}`, response)
            : await this.PutFromAnotherModel<A>(this.baseUrl + id, response);
        return result;
    }

    public async DeleteAsync(url: string, id: number | string): Promise<any> {
        const response = await this.Delete(this.baseUrl + url + `/${id}`);
        return response ? true : false;
    }
}
