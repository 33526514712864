// ----------------------------------------------------------------------

import Forbidden from "../common/403";
import Topup from "../pages/topUp/topUp";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}


// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const APP_PAGES = {
  Home: '/',
  Topup: '/Topup',
  Transactions: '/transactions',
  TransactionsList: '/transactionsList',
  TransactionDetials: '/transactionDetails',
  Reciept: '/reciept',
  // Reports
  MainReport: '/reports',
  AgentReport: '/agentReport',
  TransactionReport: '/transaction-reports',
  CustomerReport: '/customer-reports',
  CountryReport: '/country-reports',
  OperatorReport: '/operator-reports',
  CommissionReport: '/commission-reports',
  PaymentReport: '/payment-reports',
  // Settings
  customers: '/customers',
  Agents: '/agents',
  Commission: '/commission',

  // Error
  Forbidden: '/forbidden',
  NotFound: '/not-found',
};

export const END_POINTS_PATH = {
  // authentication
  GetToken: 'api/accounts/getToken',
  //Transactions
  GetTransactions: 'api/transactions',
  GetAgentTransactions: 'api/transactions/GetAgentTransactions',
  GetSenderTransactions: 'api/transactions/GetSenderTransactions',
  Topup: 'api/transactions',
  TopAgain: 'api/transactions/TopUpAgain',
  GetCountries: '/transactions/GetCountries',
  GetDashboardData: '/api/transactions/GetDashboardData',
  GetLatestTransactions: '/api/transactions/GetLatestTransactions',
  GetShortcuts: '/api/transactions/GetShortcuts',
  TopupShortcut: '/api/transactions/TopupShortcut',
  GetCustomers: '/api/transactions/GetExistedCustomerFromDahabOnline',
  GetCurrentRate: '/api/transactions/GetCurrentRate',
  FilterTransactions: '/api/transactions/FilterTransactions',
  GetBalances: '/api/transactions/GetBalances',

  //SochiTel
  GetOperators: '/SochitelServices/GetOperators',
  GetOperatorProducts: '/SochitelServices/GetOperatorProducts',
  CheckRecipientPhoneNumber: '/SochitelServices/CheckRecipientPhoneNumber',

  //Agents
  Agents: '/api/agents',
  ChangeRole: '/api/agents/ChangeRole',
  Deactivate: '/api/agents/Deactivate',
}




