import { useState, useEffect } from "react";
import { END_POINTS_PATH } from "../../routes/paths";
import { EntityServices } from "../../Services/EntityServices";
import ShowSnackbar from "../../Services/snackbar";
import { IBalance, ITransaction, ITransactionFilter } from "../../types/types";
import ReportTable from "./reportTable";
import { useNavigate } from "react-router-dom";

export default function CommissionReport() {
    const navigate = useNavigate();
    const services = new EntityServices(navigate);

  var _balances = [] as IBalance[];

  const [balances, setBalances] = useState(_balances);
  const [loading, setLoading] = useState(true);
  const [originalBalances, setOriginalBalances] = useState(_balances);

  var defaultFilter = {
    From: new Date(new Date().setDate(new Date().getDate() - 365))
      .toISOString()
      .split("T")[0],
    To: new Date().toISOString().split("T")[0],
  };

  const [balanceFilter, setBalanceFilter] = useState(defaultFilter);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    var response = await services.GetList(END_POINTS_PATH.GetBalances);
    if (response?.statusCode === 200) {
      setBalances(response.data);
      setOriginalBalances(response.data);
    }
  };

  // search balance
  const searchBalance = () => {
    if (balanceFilter.From === "" || balanceFilter.To === "") {
      setBalances(originalBalances);
      return;
    }

    const filteredBalance = originalBalances.filter((balance) => {
      return (
        new Date(balance.createdAt).getTime() >=
          new Date(balanceFilter.From).getTime() &&
        new Date(balance.createdAt).getTime() <=
          new Date(balanceFilter.To).getTime()
      );
    });

    setBalances(filteredBalance);
  };

  return (
    <div className="hor-content main-content mt-0">
      <div className="side-app">
        <div className="main-container container">
          {/* Transactions Table */}

          <div className="row mt-5">
            <div className="row px-0 mx-0">
              <div className="col-md-12 col-xl-12 px-0 mx-0">
                <div className="card bg-white shadow-sm">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-xl col-lg-6 col-md-12">
                        <div className="btn-list">
                          <h4 className="mb-4 ">
                            Balance and Commission Report
                          </h4>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 mt-1 mt-lg-0">
                        <div className="input-group">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            From
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            defaultValue={balanceFilter.From}
                            onChange={(e) =>
                              setBalanceFilter({
                                ...balanceFilter,
                                From: e.target.value,
                              })
                            }
                          />
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            To
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            defaultValue={balanceFilter.To}
                            onChange={(e) =>
                              setBalanceFilter({
                                ...balanceFilter,
                                To: e.target.value,
                              })
                            }
                          />
                          <button
                            onClick={searchBalance}
                            className="btn ripple btn-primary text-white input-group-text border-0"
                            type="button"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* add cards */}
            <div className="col-md-12 mx-0 px-0">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">Current Balance</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-20">
                          $
                          {
                            balances.filter(
                              (balance) =>
                                balance.createdAt === balances[0].createdAt
                            )[0]?.final
                          }
                          {/* <span className="fs-13 text-muted">Balance</span> */}
                        </p>
                        <div className="counter-icon bg-success dash ms-auto box-shadow-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">Intial Balance</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-20">
                          $
                          {
                            balances.filter(
                              (balance) =>
                                balance.createdAt === balances[0].createdAt
                            )[0]?.initial
                          }
                        </p>
                        <div className="counter-icon bg-warning dash ms-auto box-shadow-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="Card bg-white">
                    <div className="card-body">
                      <h5 className="card-title">Total Commission</h5>
                      <div className="d-flex flex-row justify-content-between">
                        <p className="fs-20">
                          $
                          {balances.reduce(
                            (acc, curr) => acc + curr.commission,
                            0
                          )}
                        </p>
                        <div className="counter-icon bg-info dash ms-auto box-shadow-info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-white"
                            enable-background="new 0 0 24 24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7.5,12C7.223877,12,7,12.223877,7,12.5v5.0005493C7.0001831,17.7765503,7.223999,18.0001831,7.5,18h0.0006104C7.7765503,17.9998169,8.0001831,17.776001,8,17.5v-5C8,12.223877,7.776123,12,7.5,12z M19,2H5C3.3438721,2.0018311,2.0018311,3.3438721,2,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h14c1.6561279-0.0018311,2.9981689-1.3438721,3-3V5C21.9981689,3.3438721,20.6561279,2.0018311,19,2z M21,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H5c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h14c1.1040039,0.0014038,1.9985962,0.8959961,2,2V19z M12,6c-0.276123,0-0.5,0.223877-0.5,0.5v11.0005493C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5v-11C12.5,6.223877,12.276123,6,12,6z M16.5,10c-0.276123,0-0.5,0.223877-0.5,0.5v7.0005493C16.0001831,17.7765503,16.223999,18.0001831,16.5,18h0.0006104C16.7765503,17.9998169,17.0001831,17.776001,17,17.5v-7C17,10.223877,16.776123,10,16.5,10z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 bg-white shadow-sm">
              <div className="card">
                <div className="card-body project-list-table-container">
                  <div className="table-responsive">
                    <table className="table project-list-table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Initial Balance</th>
                          <th scope="col">Final Balance</th>
                          <th scope="col">Transaction Amount</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Commission</th>
                          <th scope="col">TTNO#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {balances.map((balance) => (
                          <tr>
                            <td>
                              {new Date(balance?.createdAt).toLocaleString()}
                            </td>
                            <td className="text-end">${balance?.initial}</td>
                            <td className="text-end">${balance?.final}</td>
                            <td className="text-end">
                              ${balance?.transaction}
                            </td>
                            <td>{balance?.currency}</td>
                            <td className="text-end">${balance?.commission}</td>
                            <td>{balance?.transactionId}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={2}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-14 mb-0">
                                  Total Consumed Amount
                                </p>
                              </div>
                              <div>
                                <p className="text-14 fw-700 mb-0">
                                  $
                                  {balances
                                    .reduce(
                                      (acc, curr) =>
                                        acc + curr.initial - curr.final,
                                      0
                                    )
                                    .toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td colSpan={2}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-14 mb-0">
                                  Total Transactions
                                </p>
                              </div>
                              <div>
                                <p className="text-14 fw-700 mb-0">
                                  $
                                  {balances.reduce(
                                    (acc, curr) => acc + curr.transaction,
                                    0
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td colSpan={2}>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-14 mb-0">Total Commission</p>
                              </div>
                              <div>
                                <p className="text-14 fw-700 mb-0">
                                  $
                                  {balances.reduce(
                                    (acc, curr) => acc + curr.commission,
                                    0
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
